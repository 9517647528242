import { request } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_ALARM_MAINLIST = 'Notification/NotificationList'
const ENDPOINT_ALARM_SETTINGLIST = 'Notification/DiarySystemAlarmList'
const ENDPOINT_SAVE_ALARM = 'Notification/SaveDiaryUserSystemAlarm'
const ENDPOINT_SAVE_ALARM_SWITCH = 'Notification/SaveLearnBodyUserNotificationSystemAlarm'
const ENDPOINT_ALARM_DETAIL = 'Notification/DiarySystemAlarmDetail'
const ENDPOINT__DELETE_ALARM = 'Notification/DeleteDiaryUserSystemAlarm'
const ENDPOINT__SAVE_ALARM_READ = 'Notification/NotificationReadSave'


export default {
  namespaced: true,
  state: {
    alarmList: null,
    diaryAlarmList: null,
    alarmDetailInfo: null,
    currentTabIndex: 0,
    diaryIndex: null,
    challengeIndex: null
  },
  actions: {
    // 알람 페이지 내 리스트 조회
    async getAlarmList ({ state }, payload) {
      const resp = await request(ENDPOINT_ALARM_MAINLIST, 'post', {
        categoryCode: payload.categoryCode,
        numberPerData: 20,
        currentIndex: payload.currentIndex,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeId: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.alarmList = resp.data
      }
    },
    // 다이어리 설정페이지 내 리스트 조회
    async getDiaryAlarmList ({ state }, payload) {
      const resp = await request(ENDPOINT_ALARM_SETTINGLIST, 'post', {
        categoryCode: payload.categoryCode,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeId: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.diaryAlarmList = resp.data
      }
    },
    async setDiaryAlarmSwitch ({ state }, payload) {
      const resp = await request(ENDPOINT_SAVE_ALARM_SWITCH, 'post', {
        learnBodyNotificationCode: payload.learnBodyNotificationCode,
        isOn: payload.isOn,
        sort: payload.sort,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeId: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.diaryAlarmList = resp.data
      }
    },
    async getDiaryAlarmDetail ({ state }, payload) {
      const resp = await request(ENDPOINT_ALARM_DETAIL, 'post', {
        categoryCode: payload.categoryCode,
        learnBodyNotificationCode: payload.learnBodyNotificationCode,
        sort: payload.sort,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeId: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.alarmDetailInfo = resp.data
      }
    },
    async saveDiaryAlarm ({ state }, payload) {
      const resp = await request(ENDPOINT_SAVE_ALARM, 'post', {
        learnBodyNotificationCode: payload.learnBodyNotificationCode,
        repeatWeekDayArrayString: payload.repeatWeekDayArrayString,
        isAnteMeridiem: payload.isAnteMeridiem,
        hours: payload.hours,
        minutes: payload.minutes,
        sort: payload.sort,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeId: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.alarmList)
      }
    },
    async deleteDiaryAlarm ({ state }, payload) {
      const resp = await request(ENDPOINT__DELETE_ALARM, 'post', {
        categoryCode: payload.categoryCode,
        learnBodyNotificationCode: payload.learnBodyNotificationCode,
        sort: payload.sort,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeId: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.alarmList)
      }
    },
    // 조회한 알림 저장
    async saveDiaryAlarmRead ({ state }, payload) {
      const resp = await request(ENDPOINT__SAVE_ALARM_READ, 'post', {
        notiId: payload.notiId,
        notiType: payload.notiType,
        isClicked: payload.isClicked,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeId: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log('' + state.log)
      }
    },
  },
  mutations: {
    SET_CURRENT_ALARM_TAP (state, tabIndex) {
      state.currentTabIndex = tabIndex
    },
    SET_DIARY_ALARM_TAP (state, tabIndex) {
      state.diaryIndex = tabIndex
    },
    SET_CHALLENGE_ALARM_TAP (state, tabIndex) {
      state.challengeIndex = tabIndex
    },
    INIT_CURRENT_ALARM_TAP (state) {
      state.currentTabIndex = null
      state.diaryIndex = null
      state.challengeIndex = null
    },
    INIT_DIARY_DETAIL_INFO (state) {
      state.alarmDetailInfo = null
    }
  }
}