
import { request } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_DAYLIST = 'Challenge/DayList'
const ENDPOINT_CALENDAR = 'Challenge/Calendar'

export default {
  namespaced: true,
  state: {
    dayList: null,
    challengeCalendarInfo: null,
    challengeMonthlyDashboard: null,
    challengeStatisticsColumnmGroupList: null,
    challengeStartDateString: null,
    challengeEndDateString: null,
    mainAPICode: null
  },
  actions: {
    // 메인 챌린지 달력 조회
    async getDayList ({ state }, payload) {
      const resp = await request(ENDPOINT_DAYLIST, 'post', {
        selectDate: payload.selectDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.dayList = resp.data
        state.mainAPICode = resp.data.mainAPICode
      }
    },
    // 챌린지 달력 조회
    async getCalendar ({ state }, payload) {
      const resp = await request(ENDPOINT_CALENDAR, 'post', {
        selectedDay: payload.selectedDay,
        calendarType: payload.calendarType,
        year: payload.year,
        month: payload.month,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: (payload.challengeID === null || payload.challengeID === undefined) ? cookies.get('ChallengeID') : payload.challengeID,
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.challengeCalendarInfo = resp.data.calendarInfo
        state.challengeMonthlyDashboard = resp.data.monthlyDashboard
        state.challengeStatisticsColumnmGroupList = resp.data.statisticsColumnmGroupList
        state.challengeStartDateString = resp.data.challengeStartDateString
        state.challengeEndDateString = resp.data.challengeEndDateString

        let cookieCalendarFilter = cookies.get('ChallengeUserCalendarFilter')
        // 사용자가 필터 선택을 변경한 적이 있는 경우
        if (cookieCalendarFilter !== undefined && cookieCalendarFilter !== null && cookieCalendarFilter !== '') {
          // 초기 필터 선택 데이터 바인딩
          state.challengeStatisticsColumnmGroupList.forEach((optionGroup) => {
            optionGroup.statisticsColumnmList.forEach((option) => {
              let optionCode = option.cssClassName
              if (optionCode === 'bar' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'mission' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'star' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'cutlery' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'protein' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'pizza' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'plus' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'timer' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'fire' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if ((optionCode === null || optionCode === '') && cookieCalendarFilter.indexOf(optionCode + 'workouttype/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'minus' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'scale' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'muscle' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'fat' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'percent' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'money' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'water' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'menstruation' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              }
            })
          })
        }
      }
    },
  }
}
