
import { request } from '@/api'
import cookies from 'js-cookie'
import { setCookieForYear } from '@/utils/utils'
import * as userInfo from './lbDiaryUserInfo'

const ENDPOINT_WORKOUTFORM = 'V1/WorkoutForm'
const ENDPOINT_WORKOUTFORMITEM = 'V1/WorkoutFormItem'
const ENDPOINT_WORKOUTSAVE = 'V1/WorkoutSave'
const ENDPOINT_WORKOUTDETAIL = 'V1/WorkoutDetail'
const ENDPOINT_WORKOUTUPDATE = 'V1/WorkoutUpdate'
const ENDPOINT_WORKOUTDELETE = 'V1/WorkoutDelete'
const ENDPOINT_WORKOUTLIST = 'V1/WorkoutList'

export default {
  namespaced: true,
  state: {
    log: '',
    workoutForm: null,
    workoutResult: null,
    workoutDetail: null,
    loadWorkoutList: null,
    loadingMoreWorkout: true,
    pageInfo: {
      pageNum: 1,
      pageSize: 10,
      lastSort: 0,
      isEndPage: false
    },
    isNeedShowAlarmSetModal: false,
    tmpSelectedWorkoutListItem: null,
    tmpModalSelectedWorkoutListItem: null,
    fakeTmpModalSelectedWorkoutListItem: null
  },
  actions: {
    // 운동 등록 기본 데이터 조회
    async getWorkoutForm ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }
      const resp = await request(ENDPOINT_WORKOUTFORM, 'post', {
        selectedDay: payload.selectedDay,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.workoutForm = resp.data
      }
    },
    // 등록된 특정 운동 데이터 조회
    async getWorkoutFormItem ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_WORKOUTFORMITEM, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordDataKey,
        executeDate: payload.executeDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.workoutResult = resp.data
      }
    },
    // 운동 데이터 저장
    async setWorkoutSave ({ state, commit }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      // 홈트 > 운동기록으로 넘어온 경우만 아래 정보 추가
      let challengeVideoParameter = null
      if (payload.homeTrainingInfo.fromHT) {
        challengeVideoParameter = {
          todoDay: payload.homeTrainingInfo.dayNum,
          missionVideoLevel: payload.homeTrainingInfo.level
        }
      }
      
      const resp = await request(ENDPOINT_WORKOUTSAVE, 'post', {
        workoutParameter: {
          diaryUserRecordWorkoutKey: payload.diaryUserRecordWorkoutKey,
          executeDate: payload.executeDate,
          regSort: payload.regSort,
          imageUrl: payload.imageUrl,
          contents: payload.contents,
          workoutDuration: payload.workoutDuration,
          diaryWorkoutIntensityCode: payload.diaryWorkoutIntensityCode,
          diaryWorkoutTypeCode: payload.diaryWorkoutTypeCode,
          diaryTimeCode: payload.diaryTimeCode,
          executeDatetime: payload.executeDatetime,
          memo: payload.memo,
          calorie: payload.calorie,
          isShare: payload.isShare,
          diaryWorkoutAlternativeImageCode: payload.diaryWorkoutAlternativeImageCode,
          isRest: payload.isRest
        },
        challengeVideoParameter: challengeVideoParameter,
        baseParameter: {
          uId: cookies.get('UID'),
          appType: cookies.get('AppType'),
          appVersion: cookies.get('AppVersion'),
          languageCode: cookies.get('LanguageCode'),
          countryCode: cookies.get('CountryCode'),
          whereToUseCode: whereToUseCode,
          cuid: cookies.get('CUID'),
          challengeID: userInfo.default.state.userInfo?.diaryUserInfo?.uiType === 'DIARY_CHALLENGE' && userInfo.default.state.userInfo?.diaryUserInfo?.challengeId !== 0 ? cookies.get('ChallengeID') : 0,
          utcInterval: cookies.get('UTCInterval'),
          logCode: ''
        }
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);

        // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
        commit('CHECK_TOAST_INFO', resp.data.toastList)
      }

      return resp.data
    },
    // 운동 데이터 변경
    async setWorkoutUpdate ({ state, commit }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_WORKOUTUPDATE, 'post', {
        workoutParameter: {
          diaryUserRecordWorkoutKey: payload.diaryUserRecordWorkoutKey,
          executeDate: payload.executeDate,
          regSort: payload.regSort,
          imageUrl: payload.imageUrl,
          contents: payload.contents,
          workoutDuration: payload.workoutDuration,
          diaryWorkoutIntensityCode: payload.diaryWorkoutIntensityCode,
          diaryWorkoutTypeCode: payload.diaryWorkoutTypeCode,
          diaryTimeCode: payload.diaryTimeCode,
          executeDatetime: payload.executeDatetime,
          memo: payload.memo,
          calorie: payload.calorie,
          isShare: payload.isShare,
          diaryWorkoutAlternativeImageCode: payload.diaryWorkoutAlternativeImageCode, 
          isRest: payload.isRest
        },
        baseParameter: {
          uId: cookies.get('UID'),
          appType: cookies.get('AppType'),
          appVersion: cookies.get('AppVersion'),
          languageCode: cookies.get('LanguageCode'),
          countryCode: cookies.get('CountryCode'),
          whereToUseCode: whereToUseCode,
          cuid: cookies.get('CUID'),
          challengeID: userInfo.default.state.userInfo?.diaryUserInfo?.uiType === 'DIARY_CHALLENGE' && userInfo.default.state.userInfo?.diaryUserInfo?.challengeId !== 0 ? cookies.get('ChallengeID') : 0,
          utcInterval: cookies.get('UTCInterval'),
          logCode: ''
        }
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);

        // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
        commit('CHECK_TOAST_INFO', resp.data.toastList)
      }

      return resp.data
    },
    // 운동 조회
    async getWorkoutList ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_WORKOUTLIST, 'post', {
        pageSize: state.pageInfo.pageSize,
        pageNum: state.pageInfo.pageNum,
        lastSort: state.pageInfo.lastSort,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.loadWorkoutList = resp.data.workoutList
        state.pageInfo.isEndPage = resp.data.pageInfo.isEndPage
        state.pageInfo.lastSort = resp.data.pageInfo.lastSort
        if (state.pageInfo.isEndPage) state.loadingMoreWorkout = false
      }
    },
    // 운동 피드
    async getWorkoutListMore ({ state }, payload) {
      if (state.pageInfo.isEndPage || !state.loadingMoreWorkout) {
        state.loadingMoreWorkout = false
        return
      }
      state.pageInfo.pageNum = state.pageInfo.pageNum + 1;

      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_WORKOUTLIST, 'post', {
        pageSize: state.pageInfo.pageSize,
        pageNum: state.pageInfo.pageNum,
        lastSort: state.pageInfo.lastSort,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.loadWorkoutList.push(...resp.data.workoutList)
        state.pageInfo.isEndPage = resp.data.pageInfo.isEndPage
        state.pageInfo.lastSort = resp.data.pageInfo.lastSort
      }
    },
    // 운동 데이터 상세 조회
    async getWorkoutDetail ({ state }, payload) {
      const resp = await request(ENDPOINT_WORKOUTDETAIL, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordDataKey,
        executeDate: payload.executeDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.workoutDetail = resp.data
      }
    },
    // 운동 데이터 삭제
    async setWorkoutDelete ({ state }, payload) {
      const resp = await request(ENDPOINT_WORKOUTDELETE, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordDataKey,
        executeDate: payload.executeDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
      }
    },
  },
  mutations: {
    INIT_LOAD_LIST_PARAMS (state) {
      state.loadWorkoutList = null
      state.loadingMoreWorkout = true
      state.pageInfo = {
          pageNum: 1,
          pageSize: 10,
          lastSort: 0,
          isEndPage: false
      }
    },
    CHECK_TOAST_INFO (state, toastList) {
      // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
      if (toastList !== undefined && toastList !== null && toastList.length > 0) {
        let requireAlarmIdx = -1
        for (let i = 0; i < toastList.length; i++) {
          let toastInfo = toastList[i]
          let toastCode = toastInfo.toastCode
          if ('RequireAlarm' === toastCode) {
            requireAlarmIdx = i
            state.isNeedShowAlarmSetModal = true
          }
        }

        if (requireAlarmIdx !== -1) {
          toastList.splice(requireAlarmIdx, 1)
        }

        setCookieForYear('MainToastList', JSON.stringify(toastList))
      }
    },
    // 운동 기록 화면에서 선택된 운동 종류 임시 저장
    SET_TEMP_SELECTED_WORKOUT_LIST_ITEM (state, data) {
      state.tmpSelectedWorkoutListItem = data
    },
    // 사진 + 글로 기록 모달에서 선택된 운동 종류 임시 저장
    SET_TEMP_MODAL_SELECTED_WORKOUT_LIST_ITEM (state, data) {
      state.tmpModalSelectedWorkoutListItem = data
    },
    INIT_TEMP_MODAL_SELECTED_WORKOUT_LIST_ITEM (state) {
      state.tmpModalSelectedWorkoutListItem = null
    },
    // 모달 내에서만 사용하는 select 데이터
    SET_FAKE_TEMP_MODAL_SELECTED_WORKOUT_LIST_ITEM (state, data) {
      state.fakeTmpModalSelectedWorkoutListItem = data
    },
    INIT_FAKE_TEMP_MODAL_SELECTED_WORKOUT_LIST_ITEM (state) {
      state.fakeTmpModalSelectedWorkoutListItem = null
    },
  }
}
