<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
import config from "@/config";
import cookies from 'js-cookie'
import { setCookieForYear } from '@/utils/utils'
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('lbDiaryChallengeMain', ['currentChallengeID']),
    ...mapState('lbDiaryUserInfo', ['userInfo']),
  },
  methods: {
    sendDiaryAccessLog () {
      let logCode = 'DiaryAccessLog'

      let marketingLogData = JSON.stringify({
          UID: cookies.get('UID')
      })
      this.$store.dispatch('lbMarketingLog/setMarketingLog', { marketingLogData: marketingLogData, logCode: logCode })
    },
    SetWhereToUseCookie (whereToUseCode) {
      if (whereToUseCode !== undefined && whereToUseCode !== null && whereToUseCode !== '') {
        setCookieForYear('WhereToUseCode', whereToUseCode);
        // 다이어리 탭으로 바꿀 경우 현재 챌린지 ID를 쿠키에 저장
        if (whereToUseCode === 'DIARY') {
          this.SetChallengeIDCookie (this.currentChallengeID)
        }
        console.log('쿠키변경: ' + whereToUseCode);
      }
    },
    SetChallengeIDCookie (challengeID) {
      if (challengeID !== undefined && challengeID !== null && challengeID !== '') {
        setCookieForYear('ChallengeID', challengeID);
        console.log('챌린지 아이디 변경: ' + challengeID);
      }
    }
  },
  created () {
    if (config.isLocalDevelopment) {
      // 임시 사용자 정보데이터
      setCookieForYear('UID', '2701858_NoEqupiMB'); // 1981_NoEqupiMB(01000001023) / 2002_NoEqupiMB(01000001029)
      setCookieForYear('AppType', 'IOS');
      setCookieForYear('AppVersion', '2.4.06');
      setCookieForYear('OSVersion', '1.0');
      setCookieForYear('LanguageCode', 'ko');
      setCookieForYear('CountryCode', '82');
      setCookieForYear('WhereToUseCode', 'DIARY')
      setCookieForYear('CUID', '1052155')
      setCookieForYear('ChallengeID', '327')
      setCookieForYear('UTCInterval', '-540');
    }

    const urlParams = new URLSearchParams(window.location.search)
    let uid = urlParams.get('uId');
    if (uid !== undefined && uid !== null) setCookieForYear('UID', uid);
    let appType = urlParams.get('appType');
    if (appType !== undefined && appType !== null) setCookieForYear('AppType', appType);
    let appVersion = urlParams.get('appVersion');
    if (appVersion !== undefined && appVersion !== null) setCookieForYear('AppVersion', appVersion);
    let osVersion = urlParams.get('osVersion');
    if (osVersion !== undefined && osVersion !== null) setCookieForYear('OSVersion', osVersion);
    let language = urlParams.get('language');
    if (language !== undefined && language !== null) setCookieForYear('LanguageCode', language);
    let country = urlParams.get('country');
    if (country !== undefined && country !== null) setCookieForYear('CountryCode', country);
    let whereToUseCode = urlParams.get('whereToUseCode');
    if (whereToUseCode !== undefined && whereToUseCode !== null) setCookieForYear('WhereToUseCode', whereToUseCode);
    let cuid = urlParams.get('cuid');
    cuid = (cuid === undefined || cuid === null) ? urlParams.get('cuId') : cuid;
    cuid = (cuid === undefined || cuid === null) ? urlParams.get('cuID') : cuid;
    if (cuid !== undefined && cuid !== null) setCookieForYear('CUID', cuid);
    let challengeID = urlParams.get('challengeID');
    challengeID = (challengeID === undefined || challengeID === null) ? urlParams.get('challengeId') : challengeID;
    if (challengeID !== undefined && challengeID !== null) setCookieForYear('ChallengeID', challengeID);
    let utc = urlParams.get('utc');
    if (utc !== undefined && utc !== null) setCookieForYear('UTCInterval', utc);
    let datetimes = urlParams.get('datetimes');
    if (datetimes !== undefined && datetimes !== null) setCookieForYear('USER_LAST_DATETIMES', datetimes);

    let languageCode = cookies.get('LanguageCode');
    this.$i18n.locale = languageCode === undefined ? 'ko' : languageCode;

    // 커뮤니티 조회를 위한 유저 정보, 오토 로그인
    this.$store.dispatch('lbDiaryUserInfo/getUserInfo')

    // 다이어리 진입시 마켓 가입시키기 위한 함수 (알림페이지는 다이어리가 아니더라도 접근할 수 있으므로 제외)
    if(!(window.location.pathname.indexOf('/AlarmMainPage') > -1)) 
      this.$store.dispatch('lbBase/setDiaryAutoLogin')

    // vuex 스토어에 현재 참여하고 있는 챌린지 ID 저장(url내 challengeID)
    const url = window.location.href
    const targetStr = '/ChallengeMainPage'

    if (url.includes(targetStr)) {
      // url에 있는 challengeId 저장
      this.$store.commit('lbDiaryChallengeMain/SET_CURRENT_CHALLENGEID', challengeID)
    }
  },
  mounted () {
    window.SendDiaryAccessLog = this.sendDiaryAccessLog
    window.SetWhereToUseCookie = this.SetWhereToUseCookie
    window.SetChallengeIDCookie = this.SetChallengeIDCookie
    //
  }
}
</script>

<style>
@import './assets/css/normalization.css';
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>