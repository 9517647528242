
import { requestBand } from '@/api'
import axios from "axios"
import { sortArrayAscendWithNumNKor } from '@/utils/utils'

const URL_AUTHORIZE = 'https://testlearnbodynaverapi.azurewebsites.net/getAuthorizeCode'
const URL_TOKEN = 'https://testlearnbodynaverapi.azurewebsites.net/getToken'
const URL_BANDLIST = 'https://testlearnbodynaverapi.azurewebsites.net/getBandList'
const URL_POSTLIST = 'https://testlearnbodynaverapi.azurewebsites.net/getPostList'
const URL_COMMENTLIST = 'https://testlearnbodynaverapi.azurewebsites.net/getCommentList'

export default {
  namespaced: true,
  state: {
    // tokenInfo: null,
    tokenInfo: {
      access_token: ''
    },
    bandList: null,
    postListInfo: null,
    postList: [],
    commentListInfo: null,
    commentList: [],
    log: '',
    leaderSortOffset: [
      {
        LeaderName: '한소정',
        CrewDate: '0918',
        SortPoint: 0
      },
      {
        LeaderName: '박설아',
        CrewDate: '0918',
        SortPoint: 0
      },
      {
        LeaderName: '최수진',
        CrewDate: '0918',
        SortPoint: 0
      },
      {
        LeaderName: '정준희',
        CrewDate: '0918',
        SortPoint: 0
      },
      {
        LeaderName: '정은송',
        CrewDate: '0911',
        SortPoint: 0
      },
      {
        LeaderName: '장미',
        CrewDate: '0911',
        SortPoint: 0
      },
      {
        LeaderName: '정현주',
        CrewDate: '0911',
        SortPoint: 0
      },
      {
        LeaderName: '김해은',
        CrewDate: '0911',
        SortPoint: 0
      },
      {
        LeaderName: '신수정',
        CrewDate: '0911',
        SortPoint: 0
      },
      {
        LeaderName: '정한샘',
        CrewDate: '0904',
        SortPoint: 0
      },
      {
        LeaderName: '정다운',
        CrewDate: '0904',
        SortPoint: 0
      },
      {
        LeaderName: '함지연',
        CrewDate: '0904',
        SortPoint: 0
      },
      {
        LeaderName: '서소연',
        CrewDate: '0904',
        SortPoint: 0
      },
      {
        LeaderName: '추민선',
        CrewDate: '0828',
        SortPoint: 0
      },
      {
        LeaderName: '한상은',
        CrewDate: '0828',
        SortPoint: 0
      },
      {
        LeaderName: '김희수',
        CrewDate: '0828',
        SortPoint: 0
      },
      {
        LeaderName: '임성경',
        CrewDate: '0828',
        SortPoint: 0
      },
      {
        LeaderName: '구남정',
        CrewDate: '0828',
        SortPoint: 0
      },
      {
        LeaderName: '한소정',
        CrewDate: '0821',
        SortPoint: 0
      },
      {
        LeaderName: '박설아',
        CrewDate: '0821',
        SortPoint: 0
      },
      {
        LeaderName: '최수진',
        CrewDate: '0821',
        SortPoint: 0
      },
      {
        LeaderName: '정준희',
        CrewDate: '0821',
        SortPoint: 0
      },
      {
        LeaderName: '신유경',
        CrewDate: '0821',
        SortPoint: 0
      },
      {
        LeaderName: '김해은',
        CrewDate: '0814',
        SortPoint: 0
      },
      {
        LeaderName: '김수진',
        CrewDate: '0814',
        SortPoint: 0
      },
      {
        LeaderName: '김혜진',
        CrewDate: '0814',
        SortPoint: 0
      },
      {
        LeaderName: '김건희',
        CrewDate: '0814',
        SortPoint: 0
      },
      {
        LeaderName: '조성경',
        CrewDate: '0814',
        SortPoint: 0
      },
      {
        LeaderName: '이주은',
        CrewDate: '0807',
        SortPoint: 0
      },
      {
        LeaderName: '정은송',
        CrewDate: '0807',
        SortPoint: 0
      },
      {
        LeaderName: '문소연',
        CrewDate: '0807',
        SortPoint: 0
      },
      {
        LeaderName: '정한샘',
        CrewDate: '0807',
        SortPoint: 0
      },
      {
        LeaderName: '함지연',
        CrewDate: '0807',
        SortPoint: 0
      },
      {
        LeaderName: '정현주',
        CrewDate: '0807',
        SortPoint: 0
      },
      {
        LeaderName: '서소연',
        CrewDate: '0807',
        SortPoint: 0
      },
      {
        LeaderName: '이신은',
        CrewDate: '0807',
        SortPoint: 0
      },
      {
        LeaderName: '강승희',
        CrewDate: '0731',
        SortPoint: 0
      },
      {
        LeaderName: '김희수',
        CrewDate: '0731',
        SortPoint: 0
      },
      {
        LeaderName: '정다운',
        CrewDate: '0731',
        SortPoint: 0
      },
      {
        LeaderName: '한상은',
        CrewDate: '0731',
        SortPoint: 0
      },
      {
        LeaderName: '장미',
        CrewDate: '0731',
        SortPoint: 0
      },
      {
        LeaderName: '이주은',
        CrewDate: '0731',
        SortPoint: 0
      },
      {
        LeaderName: '구남정',
        CrewDate: '0731',
        SortPoint: 0
      },
      {
        LeaderName: '추민선',
        CrewDate: '0724',
        SortPoint: 0
      },
      {
        LeaderName: '박설아',
        CrewDate: '0724',
        SortPoint: 0
      },
      {
        LeaderName: '미래',
        CrewDate: '0724',
        SortPoint: 0
      },
      {
        LeaderName: '임성경',
        CrewDate: '0724',
        SortPoint: 0
      },
      {
        LeaderName: '전수린',
        CrewDate: '0724',
        SortPoint: 0
      },
      {
        LeaderName: '김효숙',
        CrewDate: '0724',
        SortPoint: 0
      },
      {
        LeaderName: '정준희',
        CrewDate: '0724',
        SortPoint: 0
      },
      {
        LeaderName: '정은송',
        CrewDate: '0710',
        SortPoint: 0
      },
      {
        LeaderName: '정한샘',
        CrewDate: '0710',
        SortPoint: 0
      },
      {
        LeaderName: '문소연',
        CrewDate: '0710',
        SortPoint: 0
      },
      {
        LeaderName: '서소연',
        CrewDate: '0710',
        SortPoint: 0
      },
      {
        LeaderName: '정현주',
        CrewDate: '0710',
        SortPoint: 0
      },
      {
        LeaderName: '이신은',
        CrewDate: '0710',
        SortPoint: 0
      },
      {
        LeaderName: '함지연',
        CrewDate: '0710',
        SortPoint: 0
      },
      {
        LeaderName: '장미래',
        CrewDate: '0626',
        SortPoint: 0
      },
      {
        LeaderName: '이주은',
        CrewDate: '0626',
        SortPoint: 0
      },
      {
        LeaderName: '전수린',
        CrewDate: '0626',
        SortPoint: 0
      },
      {
        LeaderName: '임성경',
        CrewDate: '0626',
        SortPoint: 0
      },
      {
        LeaderName: '정아영',
        CrewDate: '0626',
        SortPoint: 0
      },
      {
        LeaderName: '박은실',
        CrewDate: '0626',
        SortPoint: 0
      },
      {
        LeaderName: '강상화',
        CrewDate: '0626',
        SortPoint: 0
      },
      {
        LeaderName: '이미나',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '이신은',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '함지연',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '정현주',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '한상은',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '김주희',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '전유진',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '이송희',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '김승원',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '서소연',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '문소연',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '구남정',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '정한샘',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '서지영',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '정은송',
        CrewDate: '0529',
        SortPoint: 0
      },
      {
        LeaderName: '장경원',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '김민서',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '한소정',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '김서영',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '추민선',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '정다운',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '이혜경',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '장미',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '정준희',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '김효숙',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '김희수',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '김수진',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '김태연',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '정성운',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '강승희',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '박설아',
        CrewDate: '0612',
        SortPoint: 0
      },
      {
        LeaderName: '이혜정',
        CrewDate: '0612',
        SortPoint: 0
      }
    ]
  },
  actions: {
    // Auto
    async getAuthorizationCode ({ state }) {
      const resp = await axios.get(URL_AUTHORIZE);

      if (resp.data !== undefined && resp.data !== null) {
        console.log(resp.data + state.log);
      }
    },
    async getAccessToken ({ state }, payload) {
      const resp = await requestBand(URL_TOKEN, 'get', {
        authorizationCode: payload.authorization_code,
        clientId: payload.client_id
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(resp.data + state.log);
        state.tokenInfo = resp.data;
      }
    },
    async getBandList ({ state }) {
      const resp = await requestBand(URL_BANDLIST, 'get', {
        accessToken: state.tokenInfo.access_token
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(resp.data + state.log);
        let originArr = resp.data.result_data.bands;

        /**
         * {
        LeaderName: '김승원',
        CrewDate: '0529',
        SortPoint: '9'
      }
         */
        // state.leaderSortOffset에 SortPoint값 설정
        let sortPoint = 1
        state.leaderSortOffset.forEach(element => {
          element.SortPoint = sortPoint
          sortPoint++
        })
        // sortCharacter에 기본 값 추가
        originArr.forEach(element => {
          element.sortCharacter = 1000
        })
        // 밴드정보에 sortPoint 값 설정
        state.leaderSortOffset.forEach(leaderInfo => {
          let tempBandInfo = originArr.filter(bandInfo => bandInfo.name.indexOf(leaderInfo.LeaderName) > -1 && bandInfo.name.indexOf(leaderInfo.CrewDate) > -1)[0]
          if (tempBandInfo !== null && tempBandInfo !== undefined) {
            tempBandInfo.sortCharacter = leaderInfo.SortPoint
          }
        })
        // originArr.forEach(element => {
        //   element.sortCharacter = element.name.replaceAll('런바디 다이어트 크루 (직장인)','')
        //   element.sortCharacter = element.sortCharacter.replaceAll('런바디 다이어트 크루 (육아맘)','')
        //   element.sortCharacter = element.sortCharacter.replaceAll('런바디 다이어트 크루 (샘이 크루장)','')
        //   element.sortCharacter = element.sortCharacter.replaceAll('런바디 다이어트 크루 (','')
        //   element.sortCharacter = element.sortCharacter.replaceAll('런바디 다이어트 크루 -','')
        //   element.sortCharacter = element.sortCharacter.replaceAll('런바디 다이어트 크루','')
        //   element.sortCharacter = element.sortCharacter.replaceAll('런바디 다이어트 크루_테스트','')
        // })
        let sortedArr = sortArrayAscendWithNumNKor(originArr)
        console.log(sortedArr)
        // var arr = [4, '사', 2, '하', 3, '아', 1, '나']
        // var sortedArray = sortArrayAscendWithNumNKor(arr)
        // console.log(sortedArray)
        
        state.bandList = resp.data;
      }
    },
    async getPostList ({ state }, payload) {
      let param = null
      if (state.postListInfo !== null && state.postListInfo.result_data.paging.next_params !== null) {
        param = {
          accessToken: state.tokenInfo.access_token,
          bandKey: payload.bandKey,
          pagingAfter: state.postListInfo.result_data.paging.next_params.after,
          pagingLimit: state.postListInfo.result_data.paging.next_params.limit
        }
      } else {
        param = {
          accessToken: state.tokenInfo.access_token,
          bandKey: payload.bandKey
        }
      }

      const resp = await requestBand(URL_POSTLIST, 'get', param)
      if (resp.data !== undefined && resp.data !== null) {
        console.log(resp.data + state.log);
        state.postListInfo = resp.data;
        if (state.postList.length === 0) {
          state.postList = resp.data.result_data.items
        } else {
          state.postList.push(...resp.data.result_data.items)
        }       
      }
    },
    async getCommentList ({ state }, payload) {
      let param = null
      if (state.commentListInfo !== null && state.commentListInfo.result_data.paging.next_params !== null) {
        param = {
          accessToken: state.tokenInfo.access_token,
          bandKey: payload.bandKey,
          postKey: payload.postKey,
          pagingAfter: state.commentListInfo.result_data.paging.next_params.after,
          pagingLimit: state.commentListInfo.result_data.paging.next_params.limit
        }
      } else {
        param = {
          accessToken: state.tokenInfo.access_token,
          bandKey: payload.bandKey,
          postKey: payload.postKey
        }
      }

      const resp = await requestBand(URL_COMMENTLIST, 'get', param)
      if (resp.data !== undefined && resp.data !== null) {
        console.log(resp.data + state.log);
        state.commentListInfo = resp.data;
        if (state.commentList.length === 0) {
          state.commentList = resp.data.result_data.items
        } else {
          state.commentList.push(...resp.data.result_data.items)
        }       
      }
    }
  },
  mutations: {
    INIT_POST_COMMENT_INFO (state) {
      state.postListInfo = null
      state.postList = []
      state.commentListInfo = null
      state.commentList = []
    },
    SET_POST_LIST (state, postList) {
      state.postList = postList
    },
    SET_COMMENT_LIST (state, commentList) {
      state.commentList = commentList
    }
  }
}
