import { request } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_UPDATE_FEED_SHARE = 'V1/RecordFeedShareUpdate'

export default {
  namespaced: true,
  actions: {
    async updateFeedSgare({ state }, payload) {
      const resp = await request(ENDPOINT_UPDATE_FEED_SHARE, 'post', {
        diaryUserRecordKey: payload.diaryUserRecordKey,
        diaryRecordCode: payload.diaryRecordCode,
        isShare: payload.isShare,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        return resp.data.result
      }
    },
  }
}