
import { request, requestReport } from '@/api'
import cookies from 'js-cookie'
import { setCookieForYear } from '@/utils/utils'
import * as userInfo from './lbDiaryUserInfo'

const ENDPOINT_MEALFORM = 'V1/MealForm'
const ENDPOINT_MEALFORMITEM = 'V1/MealFormItem'
const ENDPOINT_MEALDETAIL = 'V1/MealDetail'
const ENDPOINT_MEALSAVE = 'V1/MealSave'
const ENDPOINT_MEALUPDATE = 'V1/MealUpdate'
const ENDPOINT_MEALDELETE = 'V1/MealDelete'
const ENDPOINT_MEALFREQUENTLYUSEDLIST = 'V1/MealFrequentlyUsedList'
const ENDPOINT_MEALRECENTRECORDMEALLIST = 'V1/RecentRecordMealList'
const ENDPOINT_MEALLIST = 'V1/MealList'
const ENDPOINT_TEXTCHECKER_PROTEIN = 'textchecker/protein'

export default {
  namespaced: true,
  state: {
    log: '',
    mealRecordForm: null,
    mealRecordResult: null,
    mealRecordDetail: null,
    loadMealListWithFrequently: null,
    loadMealListWithLatest: null,
    loadMealList: null,
    loadingMoreFood: true,
    pageInfo: {
        pageNum: 1,
        pageSize: 10,
        lastSort: 0,
        isEndPage: false
    },
    savedDiaryUserRecordKey: '',
    isNeedShowAlarmSetModal: false,
    tmpModalSelectedMealListItem: null,
    fakeTmpModalSelectedMealListItem: null,
    inputedMealProteinCodes: null
  },
  actions: {
    // 식단 등록 항목 반환
    async getMealForm ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }
      const resp = await request(ENDPOINT_MEALFORM, 'post', {
        selectedDay: payload.selectedDay,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.mealRecordForm = resp.data
      }
    },
    // 등록한 식단의 항목별 결과 반환
    async getMealFormItem ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_MEALFORMITEM, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordMealKey,
        executeDate: payload.executeDate, // "2022-09-13"
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.mealRecordResult = resp.data
      }
    },
    // 식단 등록
    async setMealSave ({ state, commit }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_MEALSAVE, 'post', {
        mealParameter: {
          diaryUserRecordMealKey: payload.diaryUserRecordMealKey,
          diaryRecordMealCode: payload.diaryRecordMealCode,
          executeDate: payload.executeDate,
          regSort: payload.regSort,
          isSkip: payload.isSkip,
          imageUrl: payload.imageUrl,
          contents: payload.contents,
          diaryMealAlternativeImageCode: payload.diaryMealAlternativeImageCode,
          diaryMealPointCode: payload.diaryMealPointCode,
          diaryTimeCode: payload.diaryTimeCode,
          executeDatetime: payload.executeDatetime,
          diaryMealAmountCode: payload.diaryMealAmountCode,
          diaryMealProteinCodeArrayString: payload.diaryMealProteinCodeArrayString,
          diaryMealBlockFoodCodeArrayString: payload.diaryMealBlockFoodCodeArrayString,
          diaryMealInevitableSituationCode: payload.diaryMealInevitableSituationCode,
          memo: payload.memo,
          calorie: payload.calorie,
          carbohydrate: payload.carbohydrate,
          protein: payload.protein,
          fat: payload.fat,
          isCoachingAllowed: payload.isCoachingAllowed,
          questionToMeal: payload.questionToMeal,
          isShare: payload.isShare
        },
        baseParameter: {
          uId: cookies.get('UID'),
          appType: cookies.get('AppType'),
          appVersion: cookies.get('AppVersion'),
          languageCode: cookies.get('LanguageCode'),
          countryCode: cookies.get('CountryCode'),
          whereToUseCode: whereToUseCode,
          cuid: cookies.get('CUID'),
          challengeID: userInfo.default.state.userInfo?.diaryUserInfo?.uiType === 'DIARY_CHALLENGE' && userInfo.default.state.userInfo?.diaryUserInfo?.challengeId !== 0 ? cookies.get('ChallengeID') : 0,
          utcInterval: cookies.get('UTCInterval'),
          logCode: ''
        }
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.savedDiaryUserRecordKey = resp.data.diaryUserRecordMealKey

        // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
        commit('CHECK_TOAST_INFO', resp.data.toastList)
      }

      return resp.data
    },
    // 식단 변경
    async setMealUpdate ({ state, commit }, payload) {
      const resp = await request(ENDPOINT_MEALUPDATE, 'post', {
        mealParameter: {
          diaryUserRecordMealKey: payload.diaryUserRecordMealKey,
          diaryRecordMealCode: payload.diaryRecordMealCode,
          executeDate: payload.executeDate,
          regSort: payload.regSort,
          isSkip: payload.isSkip,
          imageUrl: payload.imageUrl,
          contents: payload.contents,
          diaryMealAlternativeImageCode: payload.diaryMealAlternativeImageCode,
          diaryMealPointCode: payload.diaryMealPointCode,
          diaryTimeCode: payload.diaryTimeCode,
          executeDatetime: payload.executeDatetime,
          diaryMealAmountCode: payload.diaryMealAmountCode,
          diaryMealProteinCodeArrayString: payload.diaryMealProteinCodeArrayString,
          diaryMealBlockFoodCodeArrayString: payload.diaryMealBlockFoodCodeArrayString,
          diaryMealInevitableSituationCode: payload.diaryMealInevitableSituationCode,
          memo: payload.memo,
          calorie: payload.calorie,
          carbohydrate: payload.carbohydrate,
          protein: payload.protein,
          fat: payload.fat,
          isCoachingAllowed: payload.isCoachingAllowed,
          questionToMeal: payload.questionToMeal,
          isShare: payload.isShare
        },
        baseParameter: {
          uId: cookies.get('UID'),
          appType: cookies.get('AppType'),
          appVersion: cookies.get('AppVersion'),
          languageCode: cookies.get('LanguageCode'),
          countryCode: cookies.get('CountryCode'),
          whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
          cuid: cookies.get('CUID'),
          challengeID: userInfo.default.state.userInfo?.diaryUserInfo?.uiType === 'DIARY_CHALLENGE' && userInfo.default.state.userInfo?.diaryUserInfo?.challengeId !== 0 ? cookies.get('ChallengeID') : 0,
          utcInterval: cookies.get('UTCInterval'),
          logCode: ''
        }
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);

        // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
        commit('CHECK_TOAST_INFO', resp.data.toastList)
      }

      return resp.data
    },
    // 자주 기록한 식단 조회
    async getMealListWithFrequently ({ state }, payload) {
      const resp = await request(ENDPOINT_MEALFREQUENTLYUSEDLIST, 'post', {
        diaryRecordCode: 'MEAL',
        diaryRecordMealCode: payload.diaryRecordMealCode,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.loadMealListWithFrequently = resp.data.mealList
      }
    },
    // 최근 기록한 끼니에 해당하는 식단 조회
    async getMealListWithLatest ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_MEALRECENTRECORDMEALLIST, 'post', {
        diaryRecordCode: 'MEAL',
        diaryRecordMealCode: payload.diaryRecordMealCode,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.loadMealListWithLatest = resp.data.mealList
      }
    },
    // 식단 조회
    async getMealList ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_MEALLIST, 'post', {
        pageSize: state.pageInfo.pageSize,
        pageNum: state.pageInfo.pageNum,
        lastSort: state.pageInfo.lastSort,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.loadMealList = resp.data.mealList
        state.pageInfo.isEndPage = resp.data.pageInfo.isEndPage
        state.pageInfo.lastSort = resp.data.pageInfo.lastSort
        if (state.pageInfo.isEndPage) state.loadingMoreFood = false
      }
    },
    // 식단 피드
    async getMealListMore ({ state }, payload) {
      if (state.pageInfo.isEndPage || !state.loadingMoreFood) {
        state.loadingMoreFood = false
        return
      }

      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      state.pageInfo.pageNum = state.pageInfo.pageNum + 1;

      const resp = await request(ENDPOINT_MEALLIST, 'post', {
        pageSize: state.pageInfo.pageSize,
        pageNum: state.pageInfo.pageNum,
        lastSort: state.pageInfo.lastSort,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.loadMealList.push(...resp.data.mealList)
        state.pageInfo.isEndPage = resp.data.pageInfo.isEndPage
        state.pageInfo.lastSort = resp.data.pageInfo.lastSort
      }
    },
    // 식단 상세 화면 데이터 조회
    async getMealDetail ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_MEALDETAIL, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordDataKey,
        executeDate: payload.executeDate, // "2022-09-13"
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.mealRecordDetail = resp.data
      }
    },
    // 식단 데이터 삭제
    async setMealDelete ({ state }, payload) {
      const resp = await request(ENDPOINT_MEALDELETE, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordDataKey,
        executeDate: payload.executeDate, // "2022-09-13"
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
      }
    },
    // 글로 입력된 텍스트로 단백질 조회
    async getTextCheckerProtein ({ state }, payload) {
      const resp = await requestReport(ENDPOINT_TEXTCHECKER_PROTEIN, 'post', {
        Text: payload.text
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.inputedMealProteinCodes = resp.data.DiaryMealProteinCodes
      }
    },
  },
  mutations: {
    INIT_LOAD_LIST_PARAMS (state) {
      state.loadMealListWithFrequently = null
      state.loadMealList = null
      state.loadingMoreFood = true
      state.pageInfo = {
          pageNum: 1,
          pageSize: 10,
          lastSort: 0,
          isEndPage: false
      }
    },
    CHECK_TOAST_INFO (state, toastList) {
      // toastList = [{
      //   "toastCode": "Mileage",
      //   "toastMessage": "좋아요! 이번 기록으로 적립금 +30원",
      //   "toastSubMessage": "현재 총 적립금은 {totalMileage}원입니다."
      // },{
      //   "toastCode": "RequireAlarm",
      //   "toastMessage": "오류오류",
      //   "toastSubMessage": "오류오류오류오류오류오류"
      // },{
      //   "toastCode": "DailyReport",
      //   "toastMessage": "리포트가 도착했습니다!",
      //   "toastSubMessage": "리포트를 확인하세요!"
      // }]
      
      // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
      if (toastList !== undefined && toastList !== null && toastList.length > 0) {
        let requireAlarmIdx = -1
        for (let i = 0; i < toastList.length; i++) {
          let toastInfo = toastList[i]
          let toastCode = toastInfo.toastCode
          if ('RequireAlarm' === toastCode) {
            requireAlarmIdx = i
            state.isNeedShowAlarmSetModal = true
          }
        }

        if (requireAlarmIdx !== -1) {
          toastList.splice(requireAlarmIdx, 1)
        }

        setCookieForYear('MainToastList', JSON.stringify(toastList))
      }
    },
    // 사진 + 글로 기록 모달에서 선택된 아이콘 종류 임시 저장
    SET_TEMP_MODAL_SELECTED_MEAL_LIST_ITEM (state, data) {
      state.tmpModalSelectedMealListItem = data
      console.log(state.tmpModalSelectedMealListItem)
    },
    INIT_TEMP_MODAL_SELECTED_MEAL_LIST_ITEM (state) {
      state.tmpModalSelectedMealListItem = null
    },
    // 모달 내에서만 사용하는 select 데이터
    SET_FAKE_TEMP_MODAL_SELECTED_MEAL_LIST_ITEM (state, data) {
      state.fakeTmpModalSelectedMealListItem = data
    },
    INIT_FAKE_TEMP_MODAL_SELECTED_MEAL_LIST_ITEM (state) {
      state.fakeTmpModalSelectedMealListItem = null
    },
    SAVE_TEMP_DATA (state) {
      state.tmpModalSelectedMealListItem = state.fakeTmpModalSelectedMealListItem
    }
  }
}
