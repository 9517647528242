import { requestMarket } from '@/api'
import cookies from 'js-cookie'
import * as userInfo from './lbDiaryUserInfo'

const ENDPOINT_AUTOLOGIN = 'Auth/CommunityAutoLogin'
const ENDPOINT_MAIN_USER_CONTENTS = 'Suggested/MainUserCustomContents'

export default {
  namespaced: true,
  state: {
    communityContentsData: null
  },
  actions: {
    // 메인 데이터 조회
    async communityAutoLogin ({ state }) {
      const resp = await requestMarket(ENDPOINT_AUTOLOGIN, 'post', {
        uid: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        recommendMemberNo: 0
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
      }
    },
    async getDiaryMainCommunityContents ({ state }) {
      const resp = await requestMarket(ENDPOINT_MAIN_USER_CONTENTS, 'post', {
        communityMenuCode: '',
        categoryCode: '',
        searchText: '',
        genderArrayString: userInfo.default.state.userInfo.inBodyUserInfo.gender,
        ageGroupArrayString: userInfo.default.state.userInfo.inBodyUserInfo.ageGroupArrayString,
        hashTagIdArrayString: '',
        exceptHashTagId: 0,
        isFiltered: true,
        orderType: '',
        isUnRead: true,
        pageNum: 0,
        pageSize: 0,
        areaCode: '',
        readPostIDList: [
          0
        ],
        readDietMealWhatToEatIDXList: [
          0
        ],
        mallAccessToken: '',
        uid: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        callLocation: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.communityContentsData = resp.data
      }
    },
  },
  mutations: {
  }
}