import { isAppleDevice } from '@/utils/utils'
import cookies from 'js-cookie'

/**
 * Date를 'yyyy-MM-dd HH:mm:ss' 형식의 string으로 반환
 * @param { Date } issueDate string으로 변환하고자 하는 Date
 * @param { string } separator 반환될 string 중 년/월/일 사이에 넣을 날짜 구분자. 만약 separator를 지정하지 않으면, '-'가 들어감
 * @returns issueDate의 날짜를 'yyyy-MM-dd HH:mm:ss'형식의 string으로 반환
 */
export function getStringYmdtFromIssueDate (issueDate, separator) {
  if (issueDate.constructor !== Date) {
    return ''
  }
  let month = issueDate.getMonth() + 1
  let day = issueDate.getDate()
  let hours = issueDate.getHours()
  let minutes = issueDate.getMinutes()
  let seconds = issueDate.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }
  const reDate = issueDate.getFullYear() + separator + month + separator + day + ' ' + hours + ':' + minutes + ':' + seconds

  return reDate
}

/**
 * Date를 'yyyy-MM-dd HH:mm:ss' 형식의 string으로 반환
 * @param { Date } issueDate string으로 변환하고자 하는 Date
 * @param { string } separator 반환될 string 중 년/월/일 사이에 넣을 날짜 구분자. 만약 separator를 지정하지 않으면, '-'가 들어감
 * @returns issueDate의 날짜를 'yyyy-MM-dd HH:mm:ss'형식의 string으로 반환
 */
export function getStringYYYYMMDDHHmmSSFromIssueDate (issueDate) {
  if (issueDate.constructor !== Date) {
    return ''
  }
  let month = issueDate.getMonth() + 1
  let day = issueDate.getDate()
  let hours = issueDate.getHours()
  let minutes = issueDate.getMinutes()
  let seconds = issueDate.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  
  const reDate = issueDate.getFullYear() + '' + month + '' + day + '' + hours + '' + minutes + '' + seconds

  return reDate
}

/**
 * Date를 'yyyy-MM-dd' 형식의 string으로 반환
 * @param { Date } issueDate string으로 변환하고자 하는 Date
 * @param { string } separator 반환될 string 중 년/월/일 사이에 넣을 날짜 구분자. 만약 separator를 지정하지 않으면, '-'가 들어감
 * @returns issueDate의 날짜를 'yyyy-MM-dd'형식의 string으로 반환
 */
 export function getStringYmdFromIssueDate (issueDate, separator) {
  if (issueDate.constructor !== Date) {
    return ''
  }
  let month = issueDate.getMonth() + 1
  let day = issueDate.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }
  const reDate = issueDate.getFullYear() + separator + month + separator + day;

  return reDate
}

/**
 * Date를 'yyyy년 MM월 dd일' 형식의 string으로 반환
 * @param { Date } issueDate string으로 변환하고자 하는 Date
 * @param { string } yearText '년' 텍스트
 * @param { string } monthText '월' 텍스트
 * @param { string } dayText '일' 텍스트
 * @returns issueDate의 날짜를 'yyyy년 MM월 dd일'형식의 string으로 반환
 */
 export function getStringYmdWithSubText (issueDate, yearText, monthText, dayText) {
  if (issueDate.constructor !== Date) {
    return ''
  }
  let month = issueDate.getMonth() + 1
  let day = issueDate.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  const reDate = issueDate.getFullYear() + yearText + ' ' + month + monthText + ' ' + day + dayText;

  return reDate
}

/**
 * Date를 'HH:mm:ss' 형식의 string으로 반환
 * @param {*} issueDate string으로 변환하고자 하는 Date
 * @returns issueDate의 시분초를 'HH:mm:ss'형식의 string으로 반환
 */
export function getStringHmsFromIssueDate (issueDate) {
  if (issueDate.constructor !== Date) {
    return ''
  }
  let hours = issueDate.getHours()
  let minutes = issueDate.getMinutes()
  let seconds = issueDate.getSeconds()

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  const reDate = hours + ':' + minutes + ':' + seconds

  return reDate
}

/**
 * 'yyyy-MM-dd HH:mm:ss' 형식의 string date를 Date객체로 변환
 * @param { string } issueYmdt 'yyyy-MM-dd HH:mm:ss' 형식의 string
 * @returns issueYmdt에 기반된 Date 값 반환
 */
export function getDateFromIssueYmdt (issueYmdt) {
  if (isAppleDevice()) {
      // 2022-01-10 15:54:04
      let arrYMDHMS = issueYmdt.split(' ')
      let arrYMD = arrYMDHMS[0].split('-')
      let arrHMS = arrYMDHMS[1].split(':')

      return new Date(arrYMD[0], arrYMD[1] - 1, arrYMD[2], arrHMS[0], arrHMS[1], arrHMS[2])
  } else {
      return new Date(issueYmdt)
  }
}

/**
 * 'yyyy-MM-dd' 형식의 string date를 Date객체로 변환
 * @param { string } issueYmd 'yyyy-MM-dd' 형식의 string
 * @returns issueYmd에 기반된 Date 값 반환
 */
 export function getDateFromIssueYmd (issueYmd) {
  if (isAppleDevice()) {
      // 2022-01-10
      let arrYMD = issueYmd.split('-')
      return new Date(arrYMD[0], arrYMD[1] - 1, arrYMD[2])
  } else {
      return new Date(issueYmd)
  }
}

/**
 * 'yyyy-MM-dd' 형식의 string date를 Date객체로 변환 & 현재 시 분 초로 입력
 * @param { string } issueYmd 'yyyy-MM-dd' 형식의 string
 * @returns issueYmd에 기반된 Date 값 반환
 */
 export function getDateFromIssueYmdWithNowHMS (issueYmd) {
  issueYmd += ' ' + getStringHmsFromIssueDate(new Date())
  if (isAppleDevice()) {
      // 2022-01-10
      let arrYMDHMS = issueYmd.split(' ')
      let arrYMD = arrYMDHMS[0].split('-')
      let arrHMS = arrYMDHMS[1].split(':')
      return new Date(arrYMD[0], arrYMD[1] - 1, arrYMD[2], arrHMS[0], arrHMS[1], arrHMS[2])
  } else {
      return new Date(issueYmd)
  }
}

/**
 * 'yyyy-MM-dd HH:mm:ss' 형식의 string date에 addDay 만큼의 날짜 수를 더한 Date객체로 변환
 * @param { string } issueYmdt 'yyyy-MM-dd HH:mm:ss' 형식의 string
 * @param { int } addDay issueYmdt에 더하고자하는 일수
 * @param { Boolean } isDate issueYmdt가 Date 형식인지 여부
 * @returns issueYmdt에 addDay 날짜 수 만큼 더해진 Date 값 반환
 */
export function addDateFromIssueYmdt (issueYmdt, addDay, isDate) {
  let newDate = null;
  if (isDate) newDate = new Date(issueYmdt);
  else newDate = getDateFromIssueYmdt(issueYmdt);
  newDate.setTime(newDate.getTime() + 1000 * 60 * 60 * 24 * addDay);
  return newDate;
}

/**
 * 'yyyy-MM-dd HH:mm:ss' 형식의 string date에 addMin 만큼의 날짜 수를 더한 Date객체로 변환
 * @param { string } issueYmdt 'yyyy-MM-dd HH:mm:ss' 형식의 string
 * @param { int } addDay issueYmdt에 더하고자하는 분
 * @param { Boolean } isDate issueYmdt가 Date 형식인지 여부
 * @returns issueYmdt에 addMin 분 만큼 더해진 Date 값 반환
 */
export function addMinuteFromIssueYmdt (issueYmdt, addMin, isDate) {
  let newDate = null;
  if (isDate) newDate = new Date(issueYmdt);
  else newDate = getDateFromIssueYmdt(issueYmdt);
  newDate.setTime(newDate.getTime() + 1000 * 60 * addMin);
  return newDate;
}

/**
 * issueYmdt의 날짜 string 중 숫자를 제외한 포함된 모든 구분자를 제거
 * @param { string } issueYmdt 구분자를 제거하고자하는 날짜 string
 * @param { string } specificSeparator issueYmdt에 포함된 특정 구분자
 * @returns issueYmdt에 날짜 정보를 제외한 모든 구분자가 제거된 string 반환
 */
export function getStringYmdtWithoutSeparator (issueYmdt, specificSeparator) {
  let newString = issueYmdt;
  if (specificSeparator !== null && specificSeparator !== undefined) {
    newString = newString.replaceAll(specificSeparator, '');
  }
  newString = newString.replaceAll('-', '')
  newString = newString.replaceAll('/', '')
  newString = newString.replaceAll(' ', '')
  newString = newString.replaceAll(':', '')
  return newString
}

/**
 * issueYmdt의 1970 년 1 월 1 일 00:00:00 UTC와 주어진 날짜 사이의 경과 시간 (밀리 초)
 * @param { string } issueYmdt 'yyyy-MM-dd HH:mm:ss' 형식의 string
 * @returns UTC와 issueYmdt의 경과 시간 (밀리 초)
 */
export function getTimeMilliFromIssueYmdt (issueYmdt) {
  let issueDate = getDateFromIssueYmdt(issueYmdt);
  return issueDate.getTime()
}

/**
 * 오늘로부터 몇일전인지 계산
 * @param { String } issueYmdt yyyy-MM-dd HH:mm:ss 형식의 String
 * @returns 
 */
export function getDiffDayFromToday (issueYmdt, isDate) {
  let date = isDate ? new Date(issueYmdt.getTime()) : getDateFromIssueYmdt(issueYmdt);
  date.setHours(23)
  date.setMinutes(59)
  date.setSeconds(59)
  date.setMilliseconds(0)
  let today = new Date();
  today.setHours(23)
  today.setMinutes(59)
  today.setSeconds(59)
  today.setMilliseconds(0)
  let diff = today.getTime() - date.getTime();
  let diffDay = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return diffDay;
}

/**
 * 두 날짜 간의 일수찾이 계산
 * @param { String } issueYmdt1 yyyy-MM-dd HH:mm:ss 형식의 String
 * @param { String } issueYmdt2 yyyy-MM-dd HH:mm:ss 형식의 String
 * @returns 
 */
 export function getDiffDayFromYmdt2 (issueYmdt1, issueYmdt2) {
  let date1 = getDateFromIssueYmdt(issueYmdt1);
  let date2 = getDateFromIssueYmdt(issueYmdt2);
  let diff = date2.getTime() - date1.getTime();
  let diffDay = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return diffDay;
}

/**
 * 두 날짜 간의 일수찾이 계산
 * @param { String } issueYmdt1 yyyy-MM-dd 형식의 String
 * @param { String } issueYmdt2 yyyy-MM-dd 형식의 String
 * @returns 
 */
export function getDiffDayFromYmd (issueYmdt1, issueYmdt2) {
  let date1 = getDateFromIssueYmd(issueYmdt1);
  let date2 = getDateFromIssueYmd(issueYmdt2);
  let diff = date2.getTime() - date1.getTime();
  let diffDay = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return diffDay;
}

/**
 * issueYmdt를 yy.MM.dd(E) HH:mm / 22.06.07 (목) 22:33 형식으로 반환
 * @param { String } issueYmdt yyyy-MM-dd HH:mm:ss 형식의 String
 */
export function getStringInBodyDateType1 (ctx, issueYmdt) {
  let languageCode = cookies.get('LanguageCode');

  let date = getDateFromIssueYmdt(issueYmdt);

  const separator = '.'
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  let strDatetimes = '';
  if (languageCode === 'ko') {
    // yy.MM.dd(E) HH:mm / 22.06.07(목) 22:33
    strDatetimes = year.toString().substring(2, 4) + separator + month + separator + day 
    + '(' + getStringWeekdayFromIssueYmdt(ctx, issueYmdt, true, false) + ')' 
    + ' ' + hours + ':' + minutes;
  } else {
    // MM.dd.yyyy(EEE) HH:mm / Jun.07.2022(Thu) 22:33
    strDatetimes = date.toLocaleString("en-US", { month: "short" }) + separator + day + separator + year
    + '(' + getStringWeekdayFromIssueYmdt(ctx, issueYmdt, true, false) + ')' 
    + ' ' + hours + ':' + minutes;
  }

  return strDatetimes;
}

/**
 * issueYmdt를 yyyy.MM.dd(E) HH:mm / 2022.06.07 (목) 22:33 형식으로 반환
 * @param { String } issueYmdt yyyy-MM-dd HH:mm:ss 형식의 String
 */
 export function getStringInBodyDateType2 (ctx, issueYmdt) {
  let languageCode = cookies.get('LanguageCode');

  let date = getDateFromIssueYmdt(issueYmdt);

  const separator = '.'
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  let strDatetimes = '';
  if (languageCode === 'ko') {
    // yy.MM.dd(E) HH:mm / 22.06.07(목) 22:33
    strDatetimes = year + separator + month + separator + day 
    + '(' + getStringWeekdayFromIssueYmdt(ctx, issueYmdt, true, false) + ')' 
    + ' ' + hours + ':' + minutes;
  } else {
    // MM.dd.yyyy(EEE) HH:mm / Jun.07.2022(Thu) 22:33
    strDatetimes = date.toLocaleString("en-US", { month: "short" }) + separator + day + separator + year
    + '(' + getStringWeekdayFromIssueYmdt(ctx, issueYmdt, true, false) + ')' 
    + ' ' + hours + ':' + minutes;
  }
  return strDatetimes;
}

/**
 * issueYmdt를 MM월 dd일(E) / 06월 07일(목) 형식으로 반환
 * @param { String } issueYmdt yyyy-MM-dd HH:mm:ss 형식의 String
 * @param { Boolean } isDate issueYmdt가 Date 형식인지 여부
 */
 export function getStringInBodyDateType3 (ctx, issueYmdt, isDate) {
  let languageCode = cookies.get('LanguageCode');

  let date = null;
  if (isDate) date = issueYmdt;
  else date = getDateFromIssueYmdt(issueYmdt);

  let month = date.getMonth() + 1
  let day = date.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  let strDatetimes = '';
  if (languageCode === 'ko') {
    // MM월 dd일(E) / 06월 07일(목)
    strDatetimes = month + ctx.$t('common_54') + ' ' + day + ctx.$t('common_55')
    + '(' + getStringWeekdayFromIssueYmdt(ctx, issueYmdt, true, isDate) + ')';
  } else {
    // MMM dd(EEE) / Jun 07(Thu)
    strDatetimes = date.toLocaleString("en-US", { month: "short" }) + ' ' + day
    + '(' + getStringWeekdayFromIssueYmdt(ctx, issueYmdt, true, isDate) + ')';
  }

  return strDatetimes;
}

/**
 * issueYmdt를 MM.dd(E) / 06.07(목) 형식으로 반환
 * @param { String } issueYmdt yyyy-MM-dd HH:mm:ss 형식의 String
 * @param { Boolean } isDate issueYmdt가 Date 형식인지 여부
 */
 export function getStringInBodyDateType4 (ctx, issueYmdt, isDate) {
  let languageCode = cookies.get('LanguageCode');

  let date = null;
  if (isDate) date = issueYmdt;
  else date = getDateFromIssueYmdt(issueYmdt);

  let month = date.getMonth() + 1
  let day = date.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  let strDatetimes = '';
  if (languageCode === 'ko') {
    // MM.dd(E) / 06.07(목)
    strDatetimes = month + '.' + day
    + '(' + getStringWeekdayFromIssueYmdt(ctx, issueYmdt, true, isDate) + ')';
  } else {
    // MMM dd(EEE) / Jun 07(Thu)
    strDatetimes = date.toLocaleString("en-US", { month: "short" }) + ' ' + day
    + '(' + getStringWeekdayFromIssueYmdt(ctx, issueYmdt, true, isDate) + ')';
  }

  return strDatetimes;
}

/**
 * issueYmdt를 yy.MM.dd(E) / 22.06.07(목) 형식으로 반환
 * @param { String } issueYmdt yyyy-MM-dd HH:mm:ss 형식의 String
 */
 export function getStringInBodyDateType5 (ctx, issueYmdt) {
  let languageCode = cookies.get('LanguageCode');

  let date = getDateFromIssueYmdt(issueYmdt);

  const separator = '.'
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  let strDatetimes = '';
  if (languageCode === 'ko') {
    // yy.MM.dd(E) HH:mm / 22.06.07(목)
    strDatetimes = year.toString().substring(2, 4) + separator + month + separator + day 
    + '(' + getStringWeekdayFromIssueYmdt(ctx, issueYmdt, true, false) + ')';
  } else {
    // MM.dd.yyyy(EEE) HH:mm / Jun.07.2022(Thu)
    strDatetimes = date.toLocaleString("en-US", { month: "short" }) + separator + day + separator + year
    + '(' + getStringWeekdayFromIssueYmdt(ctx, issueYmdt, true, false) + ')';
  }

  return strDatetimes;
}

/**
 * issueYmdt를 yy.MM.dd / 22.06.07 형식으로 반환
 * @param { String } issueYmdt yyyy-MM-dd HH:mm:ss 형식의 String
 */
 export function getStringInBodyDateType6 (ctx, issueYmdt, isDate) {
  let languageCode = cookies.get('LanguageCode');

  let date = null;
  if (isDate) date = issueYmdt;
  else date = getDateFromIssueYmdt(issueYmdt);

  const separator = '.'
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  let strDatetimes = '';
  if (languageCode === 'ko') {
    // yy.MM.dd / 22.06.07
    strDatetimes = year.toString().substring(2, 4) + separator + month + separator + day;
  } else {
    // MM.dd.yyyy / Jun.07.2022
    strDatetimes = date.toLocaleString("en-US", { month: "short" }) + separator + day + separator + year;
  }

  return strDatetimes;
}

/**
 * issueYmdt를 yy.MM.dd HH:mm:ss / 22.06.07 22:33:30 형식으로 반환
 * @param { String } issueYmdt yyyy-MM-dd HH:mm:ss 형식의 String
 */
 export function getStringInBodyDateType7 (issueYmdt) {
  let languageCode = cookies.get('LanguageCode');

  let date = getDateFromIssueYmdt(issueYmdt);

  const separator = '.'
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  let strDatetimes = '';
  if (languageCode === 'ko') {
    // yy.MM.dd HH:mm:ss / 22.06.07 22:33:30
    strDatetimes = year.toString().substring(2, 4) + separator + month + separator + day
    + ' ' + hours + ':' + minutes + ':' + seconds;
  } else {
    // MM.dd.yyyy HH:mm:ss / Jun.07.2022 22:33:33
    strDatetimes = date.toLocaleString("en-US", { month: "short" }) + separator + day + separator + year
    + ' ' + hours + ':' + minutes + ':' + seconds;
  }

  return strDatetimes;
}

/**
 * issueYmdt를 M월 d일 / 6월 7일 형식으로 반환
 * @param { String } issueYmdt yyyy-MM-dd HH:mm:ss 형식의 String
 * @param { Boolean } isDate issueYmdt가 Date 형식인지 여부
 */
 export function getStringInBodyDateType8 (ctx, issueYmdt, isDate) {
  let languageCode = cookies.get('LanguageCode');

  let date = null;
  if (isDate) date = issueYmdt;
  else date = getDateFromIssueYmdt(issueYmdt);

  let month = date.getMonth() + 1
  let day = date.getDate()

  // if (month < 10) {
  //   month = '0' + month
  // }
  // if (day < 10) {
  //   day = '0' + day
  // }

  let strDatetimes = '';
  if (languageCode === 'ko') {
    // M월 d일(E) / 6월 7일
    strDatetimes = month + ctx.$t('common_54') + ' ' + day + ctx.$t('common_55');
  } else {
    // MMM d(EEE) / Jun 7
    strDatetimes = date.toLocaleString("en-US", { month: "short" }) + ' ' + day;
  }

  return strDatetimes;
}

/**
 * issueYmdt의 요일 반환
 * @param { String } issueYmdt yyyy-MM-dd HH:mm:ss 형식의 String
 */
export function getStringWeekdayFromIssueYmdt (ctx, issueYmdt, isShort, isDate) {
  let date = null;
  if (isDate) date = issueYmdt;
  else date = getDateFromIssueYmdt(issueYmdt);
  let weekday = '';
  let dayOfWeek = date.getDay();

  switch (dayOfWeek) {
    case 0:
      if (isShort) weekday = ctx.$t('common_sunday_short')
      else weekday = ctx.$t('common_sunday_long')
      break;
    case 1:
      if (isShort) weekday = ctx.$t('common_monday_short')
      else weekday = ctx.$t('common_monday_long')
      break;
    case 2:
      if (isShort) weekday = ctx.$t('common_tuesday_short')
      else weekday = ctx.$t('common_tuesday_long')
      break;
    case 3:
      if (isShort) weekday = ctx.$t('common_wednesday_short')
      else weekday = ctx.$t('common_wednesday_long')
      break;
    case 4:
      if (isShort) weekday = ctx.$t('common_thursday_short')
      else weekday = ctx.$t('common_thursday_long')
      break;
    case 5:
      if (isShort) weekday = ctx.$t('common_friday_short')
      else weekday = ctx.$t('common_friday_long')
      break;
    case 6:
      if (isShort) weekday = ctx.$t('common_saturday_short')
      else weekday = ctx.$t('common_saturday_long')
      break;
  
    default:
      break;
  }

  return weekday;
}

/**
 * issueDate의 시간 반환 ex 오전 07:05
 * @param {*} issueDate Date
 * @param {*} morningText 오전 텍스트
 * @param {*} afternoonText 오후 텍스트
 */
export function getStringTimeFromDate (issueDate, morningText, afternoonText) {
  let date = issueDate;

  let timePrefix = (date.getHours() < 12) ? morningText : afternoonText;
  let hours = date.getHours();
  hours = (hours <= 12 ? hours : hours - 12)
  let minutes = date.getMinutes();

  if (hours < 10) {
    hours = '0' + hours
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }

  return timePrefix + ' ' + hours + ':' + minutes;
}

/**
 * issueDate의 시간 반환 ex 오전 7시 5분 or 오전 7시
 * @param {*} issueDate Date
 * @param {*} morningText 오전 텍스트
 * @param {*} afternoonText 오후 텍스트
 * @param {*} timeText 시 텍스트
 * @param {*} minText 분 텍스트
 */
 export function getStringTimeFromDateType2 (issueDate, morningText, afternoonText, timeText, minText, isNeedZeroMin) {
  let date = issueDate;

  let timePrefix = (date.getHours() < 12) ? morningText : afternoonText;
  let hours = date.getHours();
  hours = (hours < 12 ? hours : hours - 12)
  let minutes = date.getMinutes();

  if (isNeedZeroMin
    || (!isNeedZeroMin && minutes !== 0))
    return timePrefix + ' ' + hours + timeText + ' ' + minutes + minText;
  else
    return timePrefix + ' ' + hours + timeText;
}