
import { request } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_BLOBINFO = 'V1/BlobInfo'

export default {
  namespaced: true,
  state: {
    blobInfo: null
  },
  actions: {
    // 스토리지 정보 반환
    async getBlobInfo ({ state }) {
      const resp = await request(ENDPOINT_BLOBINFO, 'post', {
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.blobInfo = resp.data
      }
    }
  }
}
