
import { request } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_CLEARREWARD_STATUSINFO = 'Challenge/ClearRewardStatusInfo'
const ENDPOINT_MILEAGE_STATUSINFO = 'Challenge/MileageStatusInfo'
const ENDPOINT_CLEARREWARD = 'Challenge/ClearReward'

export default {
  namespaced: true,
  state: {
    clearRewardStatusInfo: null,
    mileageStatusInfo: null,
    clearRewardInfo: null
  },
  actions: {
    // 클리어 리워드 리스트
    async getClearRewardStatusInfo ({ state }) {
      const resp = await request(ENDPOINT_CLEARREWARD_STATUSINFO, 'post', {
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.clearRewardStatusInfo = resp.data
      }
    },
    // 챌린지 적립금 대시보드 항목 조회
    async getMileageStatusInfo ({ state }, payload) {
      const resp = await request(ENDPOINT_MILEAGE_STATUSINFO, 'post', {
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: payload.challengeId === null ? cookies.get('ChallengeID') : payload.challengeId,
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.mileageStatusInfo = resp.data
      }
    },
    // 챌린지 클리어 리워드
    async getClearReward ({ state }, payload) {
      const resp = await request(ENDPOINT_CLEARREWARD, 'post', {
        mileageType: payload.mileageType,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: payload.challengeId === null ||  payload.challengeId === undefined? cookies.get('ChallengeID') : payload.challengeId,
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.clearRewardInfo = resp.data
      }
    },
  }
}
