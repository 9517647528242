
import { request } from '@/api'
import cookies from 'js-cookie'
import { setCookieForYear } from '@/utils/utils'
// import * as userInfo from './lbDiaryUserInfo'

const ENDPOINT_REQUIRE_STARTDATE = 'Challenge/RequireStartdate'
const ENDPOINT_SAVE_STARTDATE = 'Challenge/SaveStartdateChallengeUser'
const ENDPOINT_REQUIRE_PRESURVEY = 'Challenge/RequirePreSurvey'

export default {
  namespaced: true,
  state: {
    startdateInfo: null,
    saveInfoResp: null,
    preSurveyInfo: null
  },
  actions: {
    // 챌린지 시작일 데이터 조회
    async getRequireStartdate ({ state }) {
      const resp = await request(ENDPOINT_REQUIRE_STARTDATE, 'post', {
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.startdateInfo = resp.data
      }
    },
    // 시작일 저장
    async setStartdate ({ state }, payload) {
      setCookieForYear('ChallengeID', payload.challengeId)
      const resp = await request(ENDPOINT_SAVE_STARTDATE, 'post', {
        startDate: payload.startDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: payload.challengeId,
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.saveInfoResp = resp.data
      }
    },
    // 사전 설문조사 필요 여부 조회
    async getRequirePreSurvey ({ state }) {
      const resp = await request(ENDPOINT_REQUIRE_PRESURVEY, 'post', {
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.preSurveyInfo = resp.data
      }
    },
  }
}
