
import { request } from '@/api'
import cookies from 'js-cookie'
import * as userInfo from './lbDiaryUserInfo'

const ENDPOINT_CALENDAR = 'V1/Calendar'
const ENDPOINT_DURINGLIST = 'Challenge/DuringList'

export default {
  namespaced: true,
  state: {
    log: '',
    calendarInfo: null,
    monthlyDashboard: null,
    statisticsColumnmGroupList: null,
    challengeDuringList: null
  },
  actions: {
    // 캘린더 조회
    async getCalendarInfo ({ state }, payload) {
      const resp = await request(ENDPOINT_CALENDAR, 'post', {
        selectedDay: payload.selectedDay,
        calendarType: payload.calendarType,
        year: payload.year,
        month: payload.month,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: (payload.challengeID === null || payload.challengeID === undefined) ? cookies.get('ChallengeID') : payload.challengeID,
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      console.log(payload.challengeID)
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.calendarInfo = resp.data.calendarInfo
        state.monthlyDashboard = resp.data.monthlyDashboard
        state.statisticsColumnmGroupList = resp.data.statisticsColumnmGroupList

        let cookieCalendarFilter = cookies.get('UserCalendarFilter')
        // 사용자가 필터 선택을 변경한 적이 있는 경우
        if (cookieCalendarFilter !== undefined && cookieCalendarFilter !== null && cookieCalendarFilter !== '') {
          // 초기 필터 선택 데이터 바인딩
          state.statisticsColumnmGroupList.forEach((optionGroup) => {
            optionGroup.statisticsColumnmList.forEach((option) => {
              let optionCode = option.cssClassName
              if (optionCode === 'bar' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'star' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'cutlery' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'protein' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'pizza' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'plus' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'timer' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'fire' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if ((optionCode === null || optionCode === '') && cookieCalendarFilter.indexOf(optionCode + 'workouttype/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'minus' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'scale' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'muscle' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'fat' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'percent' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'money' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'water' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              } else if (optionCode === 'menstruation' && cookieCalendarFilter.indexOf(optionCode + '/') > -1) {
                option.isDefaultSelected = true
              }
            })
          })
        }
      }
      
      // 남성일 경우 캘린더에서 삭제
      let gender = userInfo.default.state.userInfo.inBodyUserInfo.gender

      if (gender === 'M') {
        state.statisticsColumnmGroupList.forEach(item => {
          if (item.sort === 4) {
            for (let i = 0; i < item.statisticsColumnmList.length; i++) {
              if (item.statisticsColumnmList[i].diaryStatisticsCode === 'MENSTRUATION') {
                item.statisticsColumnmList.splice(i, 1)
              }
            }
          }
        })
      }
    },
    async getCalendarDuringList ({ state }) {
      const resp = await request(ENDPOINT_DURINGLIST, 'post', {
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        osVersion: cookies.get('OSVersion'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.challengeDuringList = resp.data.challengeDuringList
      }
    }
  }
}
