
import { request } from '@/api'
import cookies from 'js-cookie'
import { setCookieForYear } from '@/utils/utils'
import * as userInfo from './lbDiaryUserInfo'

const ENDPOINT_NOONBODYFORM = 'V1/NoonBodyForm'
const ENDPOINT_NOONBODYFORMITEM = 'V1/NoonBodyFormItem'
const ENDPOINT_NOONBODYDETAIL = 'V1/NoonBodyDetail'
const ENDPOINT_NOONBODYSAVE = 'V1/NoonBodySave'
const ENDPOINT_NOONBODYUPDATE = 'V1/NoonBodyUpdate'
const ENDPOINT_NOONBODYDELETE = 'V1/NoonBodyDelete'
const ENDPOINT_NOONBODYLIST = 'V1/NoonBodyList'

export default {
  namespaced: true,
  state: {
    log: '',
    noonBodyForm: null,
    noonBodyResult: null,
    noonBodyDetail: null,
    loadNoonBodyList: null,
    loadingMoreBodyShape: true,
    pageInfo: {
        pageNum: 1,
        pageSize: 10,
        lastSort: 0,
        isEndPage: false
    },
    isNeedShowAlarmSetModal: false
  },
  actions: {
    // 눈바디 등록 기본 데이터 조회
    async getNoonBodyForm ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_NOONBODYFORM, 'post', {
        selectedDay: payload.selectedDay,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.noonBodyForm = resp.data
      }
    },
    // 등록된 특정 눈바디 데이터 조회
    async getNoonBodyFormItem ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_NOONBODYFORMITEM, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordDataKey,
        executeDate: payload.executeDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.noonBodyResult = resp.data
      }
    },
    // 눈바디 데이터 저장
    async setNoonBodySave ({ state, commit }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_NOONBODYSAVE, 'post', {
        noonBodyParameter: {
          diaryUserRecordNoonBodyKey: payload.diaryUserRecordNoonBodyKey,
          executeDate: payload.executeDate,
          regSort: payload.regSort,
          imageUrl: payload.imageUrl,
          contents: payload.contents,
          memo: payload.memo,
          isShare: payload.isShare
        },
        baseParameter: {
          uId: cookies.get('UID'),
          appType: cookies.get('AppType'),
          appVersion: cookies.get('AppVersion'),
          languageCode: cookies.get('LanguageCode'),
          countryCode: cookies.get('CountryCode'),
          whereToUseCode: whereToUseCode,
          challengeID: userInfo.default.state.userInfo?.diaryUserInfo?.uiType === 'DIARY_CHALLENGE' && userInfo.default.state.userInfo?.diaryUserInfo?.challengeId !== 0 ? cookies.get('ChallengeID') : 0,
          utcInterval: cookies.get('UTCInterval'),
          logCode: ''
        }
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.noonBodyForm = resp.data

        // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
        commit('CHECK_TOAST_INFO', resp.data.toastList)
      }

      return resp.data
    },
    // 눈바디 조회
    async getNoonBodyList ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_NOONBODYLIST, 'post', {
        pageSize: state.pageInfo.pageSize,
        pageNum: state.pageInfo.pageNum,
        lastSort: state.pageInfo.lastSort,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.loadNoonBodyList = resp.data.noonBodyList
        state.pageInfo.isEndPage = resp.data.pageInfo.isEndPage
        state.pageInfo.lastSort = resp.data.pageInfo.lastSort
        if (state.pageInfo.isEndPage) state.loadingMoreBodyShape = false
      }
    },
    // 눈바디 피드
    async getNoonBodyListMore ({ state }, payload) {
      if (state.pageInfo.isEndPage || !state.loadingMoreBodyShape) {
        state.loadingMoreBodyShape = false
        return
      }
      state.pageInfo.pageNum = state.pageInfo.pageNum + 1;

      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_NOONBODYLIST, 'post', {
        pageSize: state.pageInfo.pageSize,
        pageNum: state.pageInfo.pageNum,
        lastSort: state.pageInfo.lastSort,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.loadNoonBodyList.push(...resp.data.noonBodyList)
        state.pageInfo.isEndPage = resp.data.pageInfo.isEndPage
        state.pageInfo.lastSort = resp.data.pageInfo.lastSort
      }
    },
    // 눈바디 데이터 변경
    async getNoonBodyUpdate ({ state, commit }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_NOONBODYUPDATE, 'post', {
        noonBodyParameter: {
          diaryUserRecordNoonBodyKey: payload.diaryUserRecordNoonBodyKey,
          executeDate: payload.executeDate,
          regSort: payload.regSort,
          imageUrl: payload.imageUrl,
          contents: payload.contents,
          memo: payload.memo,
          isShare: payload.isShare
        },
        baseParameter: {
          uId: cookies.get('UID'),
          appType: cookies.get('AppType'),
          appVersion: cookies.get('AppVersion'),
          languageCode: cookies.get('LanguageCode'),
          countryCode: cookies.get('CountryCode'),
          whereToUseCode: whereToUseCode,
          cuid: cookies.get('CUID'),
          challengeID: userInfo.default.state.userInfo?.diaryUserInfo?.uiType === 'DIARY_CHALLENGE' && userInfo.default.state.userInfo?.diaryUserInfo?.challengeId !== 0 ? cookies.get('ChallengeID') : 0,
          utcInterval: cookies.get('UTCInterval'),
          logCode: ''
        }
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);

        // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
        commit('CHECK_TOAST_INFO', resp.data.toastList)
      }
      
      return resp.data
    },
    // 눈바디 데이터 상세 조회
    async getNoonBodyDetail ({ state }, payload) {
      const resp = await request(ENDPOINT_NOONBODYDETAIL, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordDataKey,
        executeDate: payload.executeDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.noonBodyDetail = resp.data
      }
    },
    // 눈바디 데이터 삭제
    async setNoonBodyDelete ({ state }, payload) {
      const resp = await request(ENDPOINT_NOONBODYDELETE, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordDataKey,
        executeDate: payload.executeDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
      }
    },
  },
  mutations: {
    INIT_LOAD_LIST_PARAMS (state) {
      state.loadNoonBodyList = null
      state.loadingMoreBodyShape = true
      state.pageInfo = {
          pageNum: 1,
          pageSize: 10,
          lastSort: 0,
          isEndPage: false
      }
    },
    CHECK_TOAST_INFO (state, toastList) {
      // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
      if (toastList !== undefined && toastList !== null && toastList.length > 0) {
        let requireAlarmIdx = -1
        for (let i = 0; i < toastList.length; i++) {
          let toastInfo = toastList[i]
          let toastCode = toastInfo.toastCode
          if ('RequireAlarm' === toastCode) {
            requireAlarmIdx = i
            state.isNeedShowAlarmSetModal = true
          }
        }

        if (requireAlarmIdx !== -1) {
          toastList.splice(requireAlarmIdx, 1)
        }

        setCookieForYear('MainToastList', JSON.stringify(toastList))
      }
    }
  }
}
