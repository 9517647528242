
import { request } from '@/api'
import cookies from 'js-cookie'
import * as userInfo from './lbDiaryUserInfo'

const ENDPOINT_READYINFO = 'Challenge/ReadyInfo'
const ENDPOINT_FINISHINFO = 'Challenge/FinishInfo'
const ENDPOINT_SELECTEDDATE_MISSION = 'Challenge/SelectedDateMission'
const ENDPOINT_SELECTEDDATE_RECORD = 'Challenge/SelectedDateRecord'
const ENDPOINT_TOPHEADERINFO = 'Challenge/TopAreaInfo'
const ENDPOINT_BOTTOMMENU = 'Challenge/BottomMenu'
const ENDPOINT_TOPBANNER = 'Challenge/TopBanner'
const ENDPOINT_SET_CLEARREWARD_MISSION = 'Challenge/SetClearRewardBehaviorMission'

export default {
  namespaced: true,
  state: {
    readyInfo: null,
    finishInfo: null,
    missionData: null,
    recordData: null,
    topHeaderInfo: null,
    bottomMenu: null,
    challengeMealInfo: null,
    challengeWorkoutInfo: null,
    challengeWeightInfo: null,
    challengeNoonBodyInfo: null,
    challengeInBodyInfo: null,
    challengeWaterInfo: null,
    challengeMensesInfo: null,
    challengeTopBannerListInfo: null,
    challengeRecordLabel: '',
    isNeedShowAlarmSetModal: false,
    savedSelectedDate: '',
    currentChallengeID: 0
  },
  actions: {
    // 챌린지 시작일 메인 데이터 
    async getReadyInfo ({ state }, payload) {
      const resp = await request(ENDPOINT_READYINFO, 'post', {
        selectDate: payload.selectDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.readyInfo = resp.data
      }
    },
    // 챌린지 마지막날 메인 데이터 
    async getFinishInfo ({ state }, payload) {
      const resp = await request(ENDPOINT_FINISHINFO, 'post', {
        selectDate: payload.selectDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.finishInfo = resp.data
      }
    },
    // 미션탭 데이터
    async getSelectedDateMission ({ state }, payload) {
      const resp = await request(ENDPOINT_SELECTEDDATE_MISSION, 'post', {
        selectDate: payload.selectDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.missionData = resp.data
      }
    },
    // 기록탭 데이터
    async getSelectedDateRecord ({ state }, payload) {
      const resp = await request(ENDPOINT_SELECTEDDATE_RECORD, 'post', {
        selectDate: payload.selectDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.challengeMealInfo = resp.data.mealInfo
        state.challengeWorkoutInfo = resp.data.workoutInfo
        state.challengeWeightInfo = resp.data.weightInfo
        state.challengeNoonBodyInfo = resp.data.noonBodyInfo
        state.challengeInBodyInfo = resp.data.inBodyInfo
        state.challengeWaterInfo = resp.data.waterInfo
        state.challengeMensesInfo = resp.data.menstruationInfo
        state.challengeRecordLabel = resp.data.challengeRecordLabel
      }
    },
    // 헤더 데이터
    async getTopHeaderInfo ({ state }) {
      const resp = await request(ENDPOINT_TOPHEADERINFO, 'post', {
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.topHeaderInfo = resp.data
      }
    },
    // 헤더 데이터
    async getBottomMenu ({ state }) {
      const resp = await request(ENDPOINT_BOTTOMMENU, 'post', {
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.bottomMenu = resp.data
      }
    },
    // 상단 배너
    async getTopBanner ({ state }, payload) {
      let userClickedBannerCode = cookies.get('UserClickedBannerCode')
      if (userClickedBannerCode === undefined) userClickedBannerCode = null
      const resp = await request(ENDPOINT_TOPBANNER, 'post', {
        selectedDay: payload.selectDate,
        topBannerCodeArrayString: userClickedBannerCode,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: '',
        memberNo: userInfo.default.state.userInfo?.diaryUserInfo?.memberNo
      })
      if (resp.data !== undefined && resp.data !== null) {
        state.challengeTopBannerListInfo = resp.data
      }
    },
    // 미션 수행 완료 후 호출
    async setClearRewardBehaviorMission (context, payload) {
      const resp = await request(ENDPOINT_SET_CLEARREWARD_MISSION, 'post', {
        challengeBehaviorMissionCode: payload.challengeBehaviorMissionCode,
        executeDate: payload.executeDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: 'CHALLENGE',
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        osVersion: cookies.get('OSVersion'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
        return resp.data.toastList
      }
    },
  },
  mutations: {
    SET_CURRENT_CHALLENGEID (state, challengeID) {
      // 사용자가 현재 참여중인 챌린지
      state.currentChallengeID = challengeID
      console.log('SET_CURRENT_CHALLENGEID: '+challengeID)
    }
  }
}
