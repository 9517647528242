
import { request } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_MARKETINGLOG = 'Log/SetMarketingLog'

/**
 * EMPTY = 0
 * PRODUCTVIEW = 1
 * FAVORITEADD = 2
 * FAVORITEREMOVE = 3
 */

export const MARKETINGLOG_EMPTY = 0
export const MARKETINGLOG_PRODUCTVIEW = 1
export const MARKETINGLOG_FAVORITEADD = 2
export const MARKETINGLOG_FAVORITEREMOVE = 3

export const MARKETINGLOG_STORAGE_LEARNBODYMARKET = 'LearnBodyMarketLog'
export const MARKETINGLOG_STORAGE_DIETMEALWHATTOEAT = 'DietMealWhatToEatLog'
export const MARKETINGLOG_STORAGE_MARKETING = 'MarketingLog'

export default {
  namespaced: true,
  state: {
    tableName: '',
  },
  actions: {
    async setMarketingLog ({ state }, payload ) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      state.tableName = payload.logTableName === null || payload.logTableName === undefined ? MARKETINGLOG_STORAGE_MARKETING : payload.logTableName
      request(ENDPOINT_MARKETINGLOG, 'post', {
        marketingLogData: payload.marketingLogData,
        dbTargetCode: payload.dbTargetCode,
        logCode: payload.logCode,
        logTableName: state.tableName,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        utcInterval: cookies.get('UTCInterval'),
        whereToUseCode: whereToUseCode
      })
    }
  }
}
