
import { requestMarket, request } from '@/api'
import cookies from 'js-cookie'
import { getDateFromIssueYmdt } from '@/utils/dateUtils'

const ENDPOINT_INBODYAPPCHECK = 'Auth/InBodyAppCheck'
const ENDPOINT_DIARYAUTOLOGIN = 'Auth/DiaryAutoLogin'
const ENDPOINT_REQUIREAPPUPDATE = 'V1/RequireAppUpdate'

export default {
  namespaced: true,
  state: {
    isCommonAppCall: false,
    log: '',
    diaryAutoLoginInfo: {},
    isNeedAppUpdate: false
  },
  actions: {
    // 마켓 로그인
    async getNCPAccessToken ({ state }) {
      const resp = await requestMarket(ENDPOINT_INBODYAPPCHECK, 'get', { 
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion')
      })

      if (resp.data !== undefined && resp.data !== null) {
        const d = getDateFromIssueYmdt(resp.data.mallTokenExpireDateYmdt + '')
        
        cookies.set('ncpAccessToken', resp.data.mallAccessToken, { 'expires': d })
      }
      console.log(state.log)
    },
    // 다이어리 로그인시 마켓 가입시키는 함수 (커뮤니티와 동일)
    async setDiaryAutoLogin ({ state }) {
      const resp = await requestMarket(ENDPOINT_DIARYAUTOLOGIN, 'post', { 
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion')
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.diaryAutoLoginInfo = resp.data
      }
    },
    // 앱에 랜딩 정보 전송함수 호출하면 2초동안 전송을 막는다.(중복으로 창이 뜨는 현상 수정)
    async callAppCommonRending ({ state }) {
      console.log('isCommonAppCall = true')
      state.isCommonAppCall = true

      setTimeout(() => {
        console.log('isCommonAppCall = false')
        state.isCommonAppCall = false
      }, 2000)
    },
    // 앱 업데이트 필요여부
    async getRequireAppUpdate ({ state }) {
      const resp = await request(ENDPOINT_REQUIREAPPUPDATE, 'post', {
        functionName: 'DiaryChallenge',
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.isNeedAppUpdate = resp.data.result
      }
    }
  }
}
