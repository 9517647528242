
import { requestReport } from '@/api'
import { getStringYmdFromIssueDate, getDateFromIssueYmd } from '@/utils/dateUtils'
import cookies from 'js-cookie'

const ENDPOINT_DAILY = 'report/daily'
const ENDPOINT_DAILYMEAL = 'report/daily/meal'
const ENDPOINT_DAILYWORKOUT = 'report/daily/workout'
const ENDPOINT_DAILYCHECKNEW = 'report/daily/checknew'

export default {
  namespaced: true,
  state: {
    log: '',
    reportDataMeal: null,
    reportDataWorkout: null,
    receiveReportDate: '',
    reportDatePickerInfo: {
      IsDaily: true,
      DateStringYmd: '',
      Date: null,
      ShowWarningBanner: false,
      IsPickerChanged: false,
      IsNext: false,
      IsExistPrev: true,
      IsExistNext: true,
    },
    isDailyCheckNew: false
  },
  actions: {
    // 일간 식사/운동 통합 카테고리 리포트 
    async getDailyReport ({ state }, payload) {
      const resp = await requestReport(ENDPOINT_DAILY, 'get', { 
        UID: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        DatestringYmd: payload.datetimeYmd,
        IsNext: state.reportDatePickerInfo.IsNext
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log)
        state.receiveReportDate = resp.data.DatestringYmd
        state.reportDataMeal = resp.data.Meal
        state.reportDataWorkout = resp.data.Workout

        // 리포트 조회 날짜와 응답된 날짜가 다른경우
        if (payload.datetimeYmd !== state.receiveReportDate) {
          state.reportDatePickerInfo.DateStringYmd = state.receiveReportDate
          state.reportDatePickerInfo.Date = getDateFromIssueYmd(state.receiveReportDate)

          // 리포트 조회 날짜가 오늘인데, 응답은 오늘이 아닌경우 경고 표시
          if (payload.datetimeYmd === getStringYmdFromIssueDate(new Date()))
            state.reportDatePickerInfo.ShowWarningBanner = true
          else 
            state.reportDatePickerInfo.ShowWarningBanner = false
        }

        // 이전, 다음 데이터 있는지 여부
        state.reportDatePickerInfo.IsExistPrev = resp.data.IsExistPrev
        state.reportDatePickerInfo.IsExistNext = resp.data.IsExistNext
      } else {
        state.reportDataMeal = null
        state.reportDataWorkout = null
      }
    },
    // 일간 식사 카테고리 리포트 
    async getDailyReportMeal ({ state }, payload) {
      const resp = await requestReport(ENDPOINT_DAILYMEAL, 'get', { 
        UID: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        DatestringYmd: payload.datetimeYmd
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log)
        state.reportDataMeal = resp.data.ReportData
      }
    },
    // 일간 운동 카테고리 리포트 
    async getDailyReportWorkout ({ state }, payload) {
      const resp = await requestReport(ENDPOINT_DAILYWORKOUT, 'get', { 
        UID: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        DatestringYmd: payload.datetimeYmd
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log)
        state.reportDataWorkout = resp.data.ReportData
      }
    },
    // 일간 리포트 신규 여부
    async getDailyCheckNew ({ state }) {
      const resp = await requestReport(ENDPOINT_DAILYCHECKNEW, 'get', { 
        UID: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion')
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log)
        state.isDailyCheckNew = resp.data.IsNewAvailable
      }
    },
  },
  mutations: {
    INIT_IS_DAILY_CHECK_NEW (state) {
      state.isDailyCheckNew = false
    }
  }
}
