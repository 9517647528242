
import { request } from '@/api'
import cookies from 'js-cookie'
import { setCookieForYear } from '@/utils/utils'
import * as userInfo from './lbDiaryUserInfo'

const ENDPOINT_WATERFORMITEM = 'V1/WaterFormItem'
const ENDPOINT_WATERSAVE = 'V1/WaterSave'
const ENDPOINT_WATERUPDATE = 'V1/WaterUpdate'
const ENDPOINT_WATERDELETE = 'V1/WaterDelete'

export default {
  namespaced: true,
  state: {
    waterInfo: null,
    isNeedShowAlarmSetModal: false
  },
  actions: {
    // 물 조회
    async getWaterFormItem ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_WATERFORMITEM, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordDataKey,
        executeDate: payload.executeDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.waterInfo = resp.data
      }
    },
    // 저장
    async getWaterSave ({ state, commit }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_WATERSAVE, 'post', {
        waterParameter: {
          diaryUserRecordWaterKey: payload.diaryUserRecordWaterKey,
          executeDate: payload.executeDate,
          amount: payload.amount
        },
        baseParameter: {
          uId: cookies.get('UID'),
          appType: cookies.get('AppType'),
          appVersion: cookies.get('AppVersion'),
          languageCode: cookies.get('LanguageCode'),
          countryCode: cookies.get('CountryCode'),
          whereToUseCode: whereToUseCode,
          cuid: cookies.get('CUID'),
          challengeID: userInfo.default.state.userInfo?.diaryUserInfo?.uiType === 'DIARY_CHALLENGE' && userInfo.default.state.userInfo?.diaryUserInfo?.challengeId !== 0 ? cookies.get('ChallengeID') : 0,
          utcInterval: cookies.get('UTCInterval'),
          logCode: ''
        }
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);

        // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
        commit('CHECK_TOAST_INFO', resp.data.toastList)
      }
    },
    // 물 수정
    async getWaterUpdate ({ state, commit }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_WATERUPDATE, 'post', {
        waterParameter: {
          diaryUserRecordWaterKey: payload.diaryUserRecordWaterKey,
          executeDate: payload.executeDate,
          amount: payload.amount,
        },
        baseParameter: {
          uId: cookies.get('UID'),
          appType: cookies.get('AppType'),
          appVersion: cookies.get('AppVersion'),
          languageCode: cookies.get('LanguageCode'),
          countryCode: cookies.get('CountryCode'),
          whereToUseCode: whereToUseCode,
          cuid: cookies.get('CUID'),
          challengeID: userInfo.default.state.userInfo?.diaryUserInfo?.uiType === 'DIARY_CHALLENGE' && userInfo.default.state.userInfo?.diaryUserInfo?.challengeId !== 0 ? cookies.get('ChallengeID') : 0,
          utcInterval: cookies.get('UTCInterval'),
          logCode: ''
        }
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);

        // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
        commit('CHECK_TOAST_INFO', resp.data.toastList)
      }
    },
    // 물 삭제
    async setWaterDelete ({ state }, payload) {
      const resp = await request(ENDPOINT_WATERDELETE, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordDataKey,
        executeDate: payload.executeDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
      }
    },
  },
  mutations: {
    CHECK_TOAST_INFO (state, toastList) {
      // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
      if (toastList !== undefined && toastList !== null && toastList.length > 0) {
        let requireAlarmIdx = -1
        for (let i = 0; i < toastList.length; i++) {
          let toastInfo = toastList[i]
          let toastCode = toastInfo.toastCode
          if ('RequireAlarm' === toastCode) {
            requireAlarmIdx = i
            state.isNeedShowAlarmSetModal = true
          }
        }

        if (requireAlarmIdx !== -1) {
          toastList.splice(requireAlarmIdx, 1)
        }

        setCookieForYear('MainToastList', JSON.stringify(toastList))
      }
    }
  }
}
