import { requestMarket } from '@/api'
import { requestShopBy } from '@/api'
import { addDateFromIssueYmdt, getStringYmdFromIssueDate } from '../../utils/dateUtils'
import cookies from 'js-cookie'

const ENDPOINT_ACCUMULATION = 'profile/accumulations'
const ENDPOINT_ACCUMULATIONSUMMARY = 'profile/accumulations/summary'
const ENDPOINT_MILEAGENOTIFICATIONREAD = 'Member/UserMileageNotificationRead'

export default {
  namespaced: true,
  state: {
    log: '',
    accumulationList: [],
    accumulationTotalAmt: '',
    pageInfo: {
      pageNum: 1,
      pageSize: 100,
      totalCount: 0
    },
    accumulationSummaryTotalAmt: '',
    accumulationSummaryTotalExpireAmt: ''
  },
  actions: {
    // 적립금 조회
    async getAccumulations ({ state, dispatch }, payload) {

      let today = new Date()
      let endYmd = getStringYmdFromIssueDate(today)
      let startYmd = getStringYmdFromIssueDate(addDateFromIssueYmdt(today, -183, true))

      const resp = await requestShopBy(ENDPOINT_ACCUMULATION, 'get', {
        pageNumber: state.pageInfo.pageNum,
        pageSize: state.pageInfo.pageSize,
        hasTotalCount: true,
        startYmd: startYmd,
        endYmd: endYmd,
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log + payload);
        state.accumulationList = resp.data.items
        state.accumulationTotalAmt = resp.data.totalAmt
        state.pageInfo.totalCount = resp.data.totalCount

        dispatch('fetchMoreAccumulations')
      }
    },
    async fetchMoreAccumulations ({ state, dispatch }) {

      if (state.pageInfo.totalCount <= state.pageInfo.pageSize * state.pageInfo.pageNum) {
        return
      } else {
        state.pageInfo.pageNum += 1
      }

      let today = new Date()
      let endYmd = getStringYmdFromIssueDate(today)
      let startYmd = getStringYmdFromIssueDate(addDateFromIssueYmdt(today, -183, true))

      const resp = await requestShopBy(ENDPOINT_ACCUMULATION, 'get', {
        pageNumber: state.pageInfo.pageNum,
        pageSize: state.pageInfo.pageSize,
        hasTotalCount: true,
        startYmd: startYmd,
        endYmd: endYmd,
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        if (resp.data.items !== undefined && resp.data.items !== null && resp.data.items.length > 0) {
          state.accumulationList.push(...resp.data.items)
          dispatch('fetchMoreAccumulations')
        }
      }
    },
    // 적립금 페이지 읽음 표시
    async setReadPointNotification ({ state }) {
      const resp = await requestMarket(ENDPOINT_MILEAGENOTIFICATIONREAD, 'post', {
        mallAccessToken: '',
        uid: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        callLocation: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
      }
    },
    // 적립금 요약 조회
    async getAccumulationsSummary ({ state }) {
      const resp = await requestShopBy(ENDPOINT_ACCUMULATIONSUMMARY, 'get', null)
      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.accumulationSummaryTotalAmt = resp.data.totalAvailableAmt
        state.accumulationSummaryTotalExpireAmt = resp.data.totalExpireAmt
      }
    },
  }
}
