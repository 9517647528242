export default {
  namespaced: true,
  state: {
    imageLoading: false
  },
  mutations: {
    SET_IMAGE_LOADING (state, isLoading) {
      state.imageLoading = isLoading
    }
  }
}
