import { createStore } from 'vuex'
import { applyMixin } from './storeMixin'

const requireModules = require.context('./modules', false, /\.js$/)
const modules = requireModules.keys().reduce((modules, fileName) => {
  const name = fileName.match(/([^./]+)\.js$/)[1]
  modules[name] = requireModules(fileName).default
  return modules
}, {})

export default createStore(applyMixin({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: modules
}))
