import { request } from '@/api'
import cookies from 'js-cookie'
import { setCookieForYear } from '@/utils/utils'
import * as userInfo from './lbDiaryUserInfo'

const ENDPOINT_INBODY_ADD = 'InBody/Add'
const ENDPOINT_INBODY_IGNORE = 'InBody/NotificationIgnore'

export default {
  namespaced: true,
  state: {
  },
  actions: {
    // 새로운 인바디 데이터 기록하기
    async setNewInbodyDataAdd ({ state, commit }, payload) {
      let userClickedBannerCode = cookies.get('UserClickedBannerCode')
      if (userClickedBannerCode === undefined) userClickedBannerCode = null
      const resp = await request(ENDPOINT_INBODY_ADD, 'post', {
        executeDate: payload.executeDate,
        uIdDatetimes: payload.uIdDatetimes,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: userInfo.default.state.userInfo?.diaryUserInfo?.uiType === 'DIARY_CHALLENGE' && userInfo.default.state.userInfo?.diaryUserInfo?.challengeId !== 0 ? cookies.get('ChallengeID') : 0,
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log('' + state.log + resp.data)
        // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
        commit('CHECK_TOAST_INFO', resp.data.toastList)
      }
    },
    // 새로운 인바디 데이터 무시
    async setNewInbodyDataIgnore ({ state }, payload) {
      const resp = await request(ENDPOINT_INBODY_IGNORE, 'post', {
        executeDate: payload.executeDate,
        uIdDatetimes: payload.uIdDatetimes,
        uId: cookies.get('UID'),
        PostID: payload.PostID,
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log('' + state.log + resp.data)
      }
    },
  },
  mutations: {
    CHECK_TOAST_INFO (state, toastList) {
      // toastList = [{
      //   "toastCode": "Mileage",
      //   "toastMessage": "좋아요! 이번 기록으로 적립금 +30원",
      //   "toastSubMessage": "현재 총 적립금은 {totalMileage}원입니다."
      // },{
      //   "toastCode": "RequireAlarm",
      //   "toastMessage": "오류오류",
      //   "toastSubMessage": "오류오류오류오류오류오류"
      // },{
      //   "toastCode": "DailyReport",
      //   "toastMessage": "리포트가 도착했습니다!",
      //   "toastSubMessage": "리포트를 확인하세요!"
      // }]
      
      // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
      if (toastList !== undefined && toastList !== null && toastList.length > 0) {
        let requireAlarmIdx = -1
        for (let i = 0; i < toastList.length; i++) {
          let toastInfo = toastList[i]
          let toastCode = toastInfo.toastCode
          if ('RequireAlarm' === toastCode) {
            requireAlarmIdx = i
            state.isNeedShowAlarmSetModal = true
          }
        }

        if (requireAlarmIdx !== -1) {
          toastList.splice(requireAlarmIdx, 1)
        }

        setCookieForYear('MainToastList', JSON.stringify(toastList))
      }
    },
  }
}
