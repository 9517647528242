import { requestGuide } from "@/api";
import cookies from 'js-cookie';
import { setCookieForYear } from '@/utils/utils'

const ENDPOINT_ACHIEVEGOAL_DASHBOARD = 'AchieveGoal/DashBoard';
// const ENDPOINT_MEALWORKOUT_DASHBOARD = 'MealWorkout/DashBoard';

// BigData 가이드 상태
export const CHANGE_RATIO_STATUS = {
    // 없음
    EMPTY: 'EMPTY',
    // 준비
    READY: 'READY',
    // 진행중
    ING: 'ING',
    // 변화율 데이터 없음
    NODATA: 'NODATA',
    // 종료
    END: 'END'
}

// 식단 가이드 상태
export const MEAL_WORKOUT_STATUS = {
    // 없음
    EMPTY: 'EMPTY',
    // 사용
    USE: 'USE',
    // 마이그레이션 이용자
    MIGRATION: 'MIGRATION',
}

export default {
    namespaced: true,
    state: {
        bigDataGuideDashboardData: null,
        dietGuideDashboardData: null,
    },
    actions: {
        async getBigDataGuideDashBoard ({ state }, payload) {
            const resp = await requestGuide(ENDPOINT_ACHIEVEGOAL_DASHBOARD, "post", {
                cuid: cookies.get('CUID'),
                challengeID: cookies.get('ChallengeID'),
                selectDate: payload.selectDate,
                uId: cookies.get('UID'),
                appType: cookies.get('AppType'),
                appVersion: cookies.get('AppVersion'),
                languageCode: cookies.get('LanguageCode'),
                countryCode: cookies.get('CountryCode'),
                utcInterval: cookies.get('UTCInterval'),
                whereToUseCode: 'DIARY'
            });
            if (resp.data !== undefined && resp.data !== null) {
                state.bigDataGuideDashboardData = resp.data
                let userNickName = resp.data.userNickName;
                if (userNickName === null || userNickName === undefined) {
                    userNickName = 'User';   
                }
                setCookieForYear('UserNickName', userNickName);
            }
        },
        // async getDietGuideDashBoard ({ state }) {
        //     const resp = await requestGuide(ENDPOINT_MEALWORKOUT_DASHBOARD, "post", {
        //         uId: cookies.get('UID'),
        //         appType: cookies.get('AppType'),
        //         appVersion: cookies.get('AppVersion'),
        //         languageCode: cookies.get('LanguageCode'),
        //         countryCode: cookies.get('CountryCode'),
        //         utcInterval: cookies.get('UTCInterval'),
        //         whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
        //     });
        //     if (resp.data !== undefined && resp.data !== null) {
        //         console.log(resp.data);
        //         state.dietGuideDashboardData = resp.data
        //     }
        // },
    }
}