import { request } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_USERINFO = 'V1/UserInfo'
const ENDPOINT_SAVEDATAUSEAGREE = 'V1/SaveDataUseAgree'

export default {
  namespaced: true,
  state: {
    userInfo: null,
    log: ''
  },
  actions: {
    async getUserInfo ({ state }) {
      const resp = await request(ENDPOINT_USERINFO, 'post', {
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeId: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.userInfo = resp.data
      }
    },
    async setUseAgree ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_SAVEDATAUSEAGREE, 'post', {
        isDataUseAgree: true,
        isDataToThirdPartiesAgree: true,
        isTermsAndConditionsAgree: true,
        isCommunityUseAgree: true,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeId: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log)
      }
    }
  }
}