import { request } from "@/api";

export default {
    namespaced: true,
    state: {
        tempValue: {
            count: 0,
        },
    },
    actions: {
        async testcall() {
            const resp = await request("Product/MixDisplayCategory", "post", {
            MallAccessToken: null,
            uid: null,
            appType: null,
            appVersion: null,
            callLocation: null,
            });
            if (resp.data !== undefined && resp.data !== null) {
            console.log("state.recommendedFilterList");
            console.log(resp.data.recommendedFilterList);
            }
        },
    },
    mutations: {
        SET_COUNT (state) {
            state.tempValue.count += 1
        },
    }
}