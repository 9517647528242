import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import('@/components/HelloWorld.vue')
  // },
  // 첫번째 페이지
  // {
  //   path: '/',
  //   name: '',
  //   component: () => import('@/components/XXX.vue')
  // },
  {
    path: '/',
    name: 'Diary_MainPage',
    component: () => import('../components/diary/DiaryMainPage.vue')
  },
  {
    path: '/RecordFoodPage',
    name: 'Diary_RecordFoodPage',
    component: () => import('../components/diary/RecordFoodPage.vue')
  },
  {
    path: '/RecordBodyShapePage',
    name: 'Diary_RecordBodyShapePage',
    component: () => import('../components/diary/RecordBodyShapePage.vue')
  },
  {
    path: '/RecordWorkoutPage',
    name: 'Diary_RecordWorkoutPage',
    component: () => import('../components/diary/RecordWorkoutPage.vue')
  },
  {
    path: '/RecordWaterPage',
    name: 'Diary_RecordWaterPage',
    component: () => import('../components/diary/RecordWaterPage.vue')
  },
  {
    path: '/RecordMensesPage',
    name: 'Diary_RecordMensesPage',
    component: () => import('../components/diary/RecordMensesPage.vue')
  },
  {
    path: '/RecordWeightPage',
    name: 'Diary_RecordWeightPage',
    component: () => import('../components/diary/RecordWeightPage.vue')
  },
  {
    path: '/DiaryMainPage',
    name: 'Diary_DiaryMainPage',
    component: () => import('../components/diary/DiaryMainPage.vue')
  },
  {
    path: '/DetailFoodPage',
    name: 'Diary_DetailFoodPage',
    component: () => import('../components/diary/DetailFoodPage.vue')
  },
  {
    path: '/DetailWorkoutPage',
    name: 'Diary_DetailWorkoutPage',
    component: () => import('../components/diary/DetailWorkoutPage.vue')
  },
  {
    path: '/DetailWeightPage',
    name: 'Diary_DetailWeightPage',
    component: () => import('../components/diary/DetailWeightPage.vue')
  },
  {
    path: '/DetailBodyShapePage',
    name: 'Diary_DetailBodyShapePage',
    component: () => import('../components/diary/DetailBodyShapePage.vue')
  },
  {
    path: '/DetailWaterPage',
    name: 'Diary_DetailWaterPage',
    component: () => import('../components/diary/DetailWaterPage.vue')
  },
  {
    path: '/DetailMensesPage',
    name: 'Diary_DetailMensesPage',
    component: () => import('../components/diary/DetailMensesPage.vue')
  },
  {
    path: '/DiaryCalendarMainPage',
    name: 'Diary_DiaryCalendarMainPage',
    component: () => import('../components/diary/DiaryCalendarMainPage.vue')
  },
  {
    path: '/DiaryRewardPage',
    name: 'Diary_DiaryRewardPage',
    component: () => import('../components/diary/DiaryRewardPage.vue')
  },
  {
    path: '/AlarmMainPage',
    name: 'Diary_AlarmMainPage',
    component: () => import('../components/diary/AlarmMainPage.vue')
  },
  {
    path: '/AlarmSettingPage',
    name: 'Diary_AlarmSettingPage',
    component: () => import('../components/diary/AlarmSettingPage.vue')
  },
  {
    path: '/PushAlarmSettingPage',
    name: 'Diary_PushAlarmSettingPage',
    component: () => import('../components/diary/PushAlarmSettingPage.vue')
  },
  {
    path: '/ChallengeMainPage',
    name: 'Diary_ChallengeMainPage',
    component: () => import('../components/diary/ChallengeMainPage.vue')
  },
  // 챌린지 기간을 설정해주세요 페이지
  {
    path: '/ChallengeBeforeStartPage',
    name: 'Diary_ChallengeBeforeStartPage',
    component: () => import('../components/diary/challenge/ChallengeBeforeStartPage.vue')
  },
  // 챌린지 시작날짜 선택
  {
    path: '/ChallengeSelectDatePage',
    name: 'Diary_ChallengeSelectDatePage',
    component: () => import('../components/diary/challenge/ChallengeSelectDatePage.vue')
  },
  /** 크루모집 메인 */
  {
    path: '/CrewApply',
    name: 'CrewApply',
    component: () => import('../components/crew/CrewApply')
  },
  /** 네이버로그인 */
  {
    path: '/NaverLogin',
    name: 'NaverLogin',
    component: () => import('../components/naver/NaverLogin')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
