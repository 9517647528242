
import { request } from '@/api'
import cookies from 'js-cookie'
import { setCookieForYear } from '@/utils/utils'
import * as userInfo from './lbDiaryUserInfo'

const ENDPOINT_WEIGHTFORMITEM = 'V1/WeightFormItem'
const ENDPOINT_WEIGHTSAVE = 'V1/WeightSave'
const ENDPOINT_WEIGHTUPDATE = 'V1/WeightUpdate'
const ENDPOINT_WEIGHTDELETE = 'V1/WeightDelete'
const ENDPOINT_WRITELASTWEIGHT = 'V1/WriteLastWeight'

export default {
  namespaced: true,
  state: {
    log: '',
    weightResult: null,
    savedDiaryUserRecordKey: '',
    isNeedShowAlarmSetModal: false
  },
  actions: {
    // 체중 저장
    async getWeightSave ({ state, commit }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_WEIGHTSAVE, 'post', {
        weightParameter: {
          diaryUserRecordWeightKey: payload.diaryUserRecordWeightKey,
          executeDate: payload.executeDate,
          weight: payload.weight,
        },
        baseParameter: {
          uId: cookies.get('UID'),
          appType: cookies.get('AppType'),
          appVersion: cookies.get('AppVersion'),
          languageCode: cookies.get('LanguageCode'),
          countryCode: cookies.get('CountryCode'),
          whereToUseCode: whereToUseCode,
          cuid: cookies.get('CUID'),
          challengeID: userInfo.default.state.userInfo?.diaryUserInfo?.uiType === 'DIARY_CHALLENGE' && userInfo.default.state.userInfo?.diaryUserInfo?.challengeId !== 0 ? cookies.get('ChallengeID') : 0,
          utcInterval: cookies.get('UTCInterval'),
          logCode: ''
        }
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.savedDiaryUserRecordKey = resp.data.diaryUserRecordWeightKey

        // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
        commit('CHECK_TOAST_INFO', resp.data.toastList)
      }
    },
    // 마지막 체중 조회
    async getWeightLastResult ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_WRITELASTWEIGHT, 'post', {
        executeDate: payload.executeDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.weightResult = resp.data
      }
    },
    // 체중 조회
    async getWeightFormItem ({ state }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_WEIGHTFORMITEM, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordDataKey,
        executeDate: payload.executeDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: whereToUseCode,
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
        state.weightResult = resp.data
      }
    },
    // 체중 수정
    async getWeightUpdate ({ state, commit }, payload) {
      let whereToUseCode = ''
      if (payload.whereToUseCode) {
        whereToUseCode = payload.whereToUseCode
      } else {
        whereToUseCode = cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode')
      }

      const resp = await request(ENDPOINT_WEIGHTUPDATE, 'post', {
        weightParameter: {
          diaryUserRecordWeightKey: payload.diaryUserRecordWeightKey,
          uId: cookies.get('UID'),
          executeDate: payload.executeDate,
          weight: payload.weight,
        },
        baseParameter: {
          uId: cookies.get('UID'),
          appType: cookies.get('AppType'),
          appVersion: cookies.get('AppVersion'),
          languageCode: cookies.get('LanguageCode'),
          countryCode: cookies.get('CountryCode'),
          whereToUseCode: whereToUseCode,
          cuid: cookies.get('CUID'),
          challengeID: userInfo.default.state.userInfo?.diaryUserInfo?.uiType === 'DIARY_CHALLENGE' && userInfo.default.state.userInfo?.diaryUserInfo?.challengeId !== 0 ? cookies.get('ChallengeID') : 0,
          utcInterval: cookies.get('UTCInterval'),
          logCode: ''
        }
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);

        // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
        commit('CHECK_TOAST_INFO', resp.data.toastList)
      }
    },
    // 체중 삭제
    async setWeightDelete ({ state }, payload) {
      const resp = await request(ENDPOINT_WEIGHTDELETE, 'post', {
        diaryUserRecordDataKey: payload.diaryUserRecordDataKey,
        executeDate: payload.executeDate,
        uId: cookies.get('UID'),
        appType: cookies.get('AppType'),
        appVersion: cookies.get('AppVersion'),
        languageCode: cookies.get('LanguageCode'),
        countryCode: cookies.get('CountryCode'),
        whereToUseCode: cookies.get('WhereToUseCode') === undefined ? null : cookies.get('WhereToUseCode'),
        cuid: cookies.get('CUID'),
        challengeID: cookies.get('ChallengeID'),
        utcInterval: cookies.get('UTCInterval'),
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        console.log(state.log);
      }
    },
  },
  mutations: {
    CHECK_TOAST_INFO (state, toastList) {
      // 기록이나 수정 후 토스트 or 알림 설정 모달 띄울지 정보 처리
      if (toastList !== undefined && toastList !== null && toastList.length > 0) {
        let requireAlarmIdx = -1
        for (let i = 0; i < toastList.length; i++) {
          let toastInfo = toastList[i]
          let toastCode = toastInfo.toastCode
          if ('RequireAlarm' === toastCode) {
            requireAlarmIdx = i
            state.isNeedShowAlarmSetModal = true
          }
        }

        if (requireAlarmIdx !== -1) {
          toastList.splice(requireAlarmIdx, 1)
        }

        setCookieForYear('MainToastList', JSON.stringify(toastList))
      }
    }
  }
}
