import { commonAppCall } from '@/utils/utils'
import config from '@/config'

export default {
  namespaced: true,
  state: {
    log: ''
  },
  mutations: {
    SET_APP_VIEW_FULL(state, isFull) {
      let data = {
        'ViewCode': 1,
        'RendingCode': 'FEED_SET_APP_VIEW_FULL',
        'RendingData': isFull ? '1' : '0'
      }
    
      if (!config.isLocalDevelopment)
        commonAppCall(data, true)
    },
    SET_APP_HIDE_HEADER(state, isHide) {
      let data = {
        'ViewCode': 1,
        'RendingCode': isHide ? 'FEED_SET_APP_HIDE_HEADER' : 'FEED_SET_APP_VIEW_FULL',
        'RendingData': isHide ? '1' : '0'
      }
    
      if (!config.isLocalDevelopment)
        commonAppCall(data, true)
    }
  }
}
